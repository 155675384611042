export let APIServerConfiguration;
APIServerConfiguration = {
    host: process.env.REACT_APP_BONBON_API_HOST, //APIのホスト
    nodeHost: process.env.REACT_APP_BONBON_NODE_HOST, // Nodejsのホスト(ホスト名:ポート番号 or ホスト名のみを記載) 正規表現形式で乗せる
    wsiImageList: process.env.REACT_APP_BONBON_API_HOST + '/v1/slides', //WSIImageのリストを取得するためのエンドポイント
    adminSearchSlide: process.env.REACT_APP_BONBON_API_HOST + '/v1/admin/slides',
    wsImageDelete: process.env.REACT_APP_BONBON_API_HOST + '/v1/slides/'
};

APIServerConfiguration.getLoginRedirectRegexWithCodeParam = () => {
    return RegExp('^https?:\\/\\/'+ APIServerConfiguration.nodeHost +'\\/\\?code=.+', 'g');
}

APIServerConfiguration.getLoginRedirectRegex = () => {
    return RegExp('^https?:\\/\\/'+ APIServerConfiguration.nodeHost +'\\/$', 'g')
}