import {useModal} from "react-hooks-use-modal";
import {Button, TextAreaField, View} from "@aws-amplify/ui-react";
import {useState} from "react";
import {APIServerConfiguration} from "../../../../../config/APIServerConfiguration";
import {Auth} from "aws-amplify";


export const updateAnnotation = (annotation, annotationText, viewer, annotations, setAnnotations, setControllerValue) => {
    if(document.getElementById(annotation.annotationId)) {
        const baseUrl = `${APIServerConfiguration.host}/v1/slides${window.location.pathname}`;
        const specificAnnotationUrl = `${baseUrl}/annotation/${annotation.annotationId}`;

        Auth.currentSession().then(data => {
            const accessToken = data.getAccessToken().getJwtToken();
            return fetch(specificAnnotationUrl,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then(response => response.json())
                .then(responseAnnotation => {
                    return fetch(specificAnnotationUrl, {
                        method: 'PUT',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': "application/json"
                        },
                        body: JSON.stringify({...responseAnnotation, annotationText: annotationText})
                    }).then(response => response.json())
                        .then(responseAnnotation => {
                            console.log(responseAnnotation)
                            const i = annotations.annotations.findIndex((a) => {
                                return a.annotationId === responseAnnotation.annotationId;
                            });
                            annotations.annotations[i].annotationText = annotationText;
                            setAnnotations(annotations);
                            console.log(`インデックス: ${i}`);
                        })
                        .catch(error => { console.log(error) });
                })
        })
    }
}

export const useOpenSeadragonUpdateRectangleAnnotationModal = ({annotation, annotations, setAnnotations, setControllerValue, viewer}) => {

    const [ defaultAnnotationText, setDefaultAnnotationText] = useState(annotation.annotationText);
    let annotationText = "";
    const [UpdateModal,
        openUpdateAnnotationModalSource,
        closeUpdateAnnotationModal] = useModal();

    const openUpdateAnnotationModal = () => {
        openUpdateAnnotationModalSource();
        const baseUrl= `${APIServerConfiguration.host}/v1/slides${window.location.pathname}`;
        const specificAnnotationUrl = `${baseUrl}/annotation/${annotation.annotationId}`;
        console.log(specificAnnotationUrl);
        Auth.currentSession().then(data => {
            const accessToken = data.getAccessToken().getJwtToken();
            return fetch(specificAnnotationUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            })
                .then(response => response.json())
                .then(responseAnnotation => {
                    setDefaultAnnotationText(responseAnnotation.annotationText);
                    openUpdateAnnotationModalSource();
                }).catch(console.error);
        });
    }
    const clickUpdateAnnotationButton = (event) => {
        updateAnnotation(annotation, annotationText, viewer, annotations, setAnnotations, setControllerValue);
        closeUpdateAnnotationModal();
        setControllerValue("");
    }

    const clickCancelUpdateAnnotationButton = (event) => {
        closeUpdateAnnotationModal();
        setControllerValue("");
    }

    const updateAnnotationText = (event) => {
        annotationText = event.target.value;
        console.log(annotationText);
    }

    const UpdateAnnotationModal = () =>{
        return (
            <UpdateModal>
                <View
                    ariaLabel="アノテーション削除確認"
                    backgroundColor="var(--amplify-colors-white)"
                    borderRadius="6px"
                    border="1px solid var(--amplify-colors-black)"
                    boxShadow="3px 3px 5px 6px var(--amplify-colors-neutral-60)"
                    color="var(--amplify-colors-blue-60)"
                    maxWidth="100%"
                    padding="1rem"
                >
                    <form>
                        {`アノテーション${annotation.annotationId}のテキスト情報を更新しますがよろしいですか`}
                        <TextAreaField
                            label={`アノテーション(${annotation.annotationId})の内容`}
                            rows="3"
                            defaultValue={defaultAnnotationText}
                            onChange={updateAnnotationText}
                        />
                        <Button onClick={(event) => {
                            console.log(annotationText);
                            clickUpdateAnnotationButton(event)}}>更新</Button>
                        <Button onClick={(event) => {
                            console.log(annotationText);
                            clickCancelUpdateAnnotationButton(event)}}>更新せずに閉じる</Button>
                    </form>
                </View>
            </UpdateModal>
        )
    }

    return [UpdateAnnotationModal, openUpdateAnnotationModal, closeUpdateAnnotationModal];
}