import {BrowserRouter, Route, Routes} from "react-router-dom";
import {About, Contact, Events, History, Location, Page404NotFound, Products, Services} from "./pages";
import {Home} from "./pages/Home";
import {SlideImageList} from "./slides/SlideImageList";
import {SpecificSlide} from "./slides/SpecificSlide";
import {SlideList} from "./slides/SlideList";
import {AdminSlideList} from "./admin/slides/AdminSlideList";
import {AdminSlideSearchForm} from "./admin/slides/AdminSlideSearchForm";

export const RouteSettings = () => {
    return (
        <Routes>
            <Route path="/" element={<SlideList/>}/>
            <Route path={`/:id`} element={<SlideImageList/>}/>
            <Route path={`/:id/:name`} element={<SpecificSlide />}/>
            <Route path={`/admin/slides`} element={<AdminSlideSearchForm/>}/>
        </Routes>
    )
}