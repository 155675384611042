import {useModal} from "react-hooks-use-modal";
import {Button, TextAreaField, View} from "@aws-amplify/ui-react";
import {APIServerConfiguration} from "../../../../../config/APIServerConfiguration";
import {Auth} from "aws-amplify";
import {useState} from "react";

export const removeAnnotation = (annotation, viewer, annotations, setAnnotations, setControllerValue) => {
    if (document.getElementById(annotation.annotationId)) {
        const baseUrl = `${APIServerConfiguration.host}/v1/slides${window.location.pathname}`;
        const specificAnnotationUrl = `${baseUrl}/annotation/${annotation.annotationId}`;


        Auth.currentSession().then(data => {
            const accessToken = data.getAccessToken().getJwtToken();
            return fetch(specificAnnotationUrl,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                })
                .then(response => {
                    response.json();
                })
                .catch(error => console.log(error))
        })
    }
    viewer.removeOverlay(annotation.annotationId);

    const newAnnotations = annotations.annotations.filter(a => a.annotationId !== annotation.annotationId);
    setAnnotations({
        slideId: annotations.slideId,
        annotations: newAnnotations,
    })
}

export const useOpenSeadragonDeleteRectangleAnnotationModal = ({annotation, annotations, setAnnotations, setControllerValue, viewer}) => {
    const [DeleteModal,
        openDeleteAnnotationModalSource,
        closeDeleteAnnotationModal] = useModal();

    const [defaultAnnotationText, setDefaultAnnotationText] = useState(annotation.annotationText);

    const clickDeleteAnnotationButton = (event) => {
        removeAnnotation(annotation, viewer, annotations, setAnnotations, setControllerValue);
        closeDeleteAnnotationModal();
        setControllerValue("");
    }

    const openDeleteAnnotationModal = () => {
        const baseUrl= `${APIServerConfiguration.host}/v1/slides${window.location.pathname}`;
        const specificAnnotationUrl = `${baseUrl}/annotation/${annotation.annotationId}`;
        console.log(specificAnnotationUrl);
        Auth.currentSession().then(data => {
            const accessToken = data.getAccessToken().getJwtToken();
            return fetch(specificAnnotationUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            })
                .then(response => response.json())
                .then(responseAnnotation => {
                    setDefaultAnnotationText(responseAnnotation.annotationText);
                    openDeleteAnnotationModalSource();
                }).catch(console.error);
        });
    }

    const DeleteAnnotationModal = () => {
        return (
            <DeleteModal>
                <View
                    ariaLabel="アノテーション削除確認"
                    backgroundColor="var(--amplify-colors-white)"
                    borderRadius="6px"
                    border="1px solid var(--amplify-colors-black)"
                    boxShadow="3px 3px 5px 6px var(--amplify-colors-neutral-60)"
                    color="var(--amplify-colors-blue-60)"
                    maxWidth="100%"
                    padding="1rem"
                >
                    <form>
                        {`アノテーション${annotation.annotationId}を削除しますがよろしいですか`}
                        <TextAreaField
                            label={`アノテーション(${annotation.annotationId})の内容`}
                            rows="3"
                            isDisabled={true}
                            isReadOnly={true}
                            defaultValue={defaultAnnotationText}
                        />
                        <Button onClick={clickDeleteAnnotationButton}>削除</Button>
                        <Button onClick={closeDeleteAnnotationModal}>キャンセル</Button>
                    </form>
                </View>
            </DeleteModal>)
    }

    return [DeleteAnnotationModal, openDeleteAnnotationModal, closeDeleteAnnotationModal]
}
