import {useLocation, useParams} from "react-router-dom";
import {APIServerConfiguration} from "../../config/APIServerConfiguration";
import {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import {OpenSeadragonViewer} from "./openseadragon/OpenSeadragonViewer";

export const SpecificSlide = () => {
    sessionStorage.removeItem('originatingURL');
    let path = useLocation().pathname
    console.log(path)
    let url = APIServerConfiguration.host + '/v1' + path
    console.log(url)

    const [data, setData] = useState()
    const [annotations, setAnnotations] = useState()

    useEffect(()=>{
        let url = APIServerConfiguration.host + '/v1/slides' + path
        Auth.currentSession().then(data => {
          const accessToken = data.getAccessToken().getJwtToken();
          console.log(`token is ${accessToken}`)
          return fetch(url, {
              headers: {
                  "Authorization": `Bearer ${accessToken}`
              }
          }).then(response => response.json()).then(setData).catch(console.error)
        })

        let annotationsUrl = APIServerConfiguration.host + '/v1/slides' + path + '/annotations'
        console.log(annotationsUrl);
        Auth.currentSession().then(data => {
            const accessToken = data.getAccessToken().getJwtToken();
            return fetch(annotationsUrl, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }).then(response => response.json()).then(setAnnotations).catch(console.error)
        })
    },[])

    const pathList = path.split("/")
    const wsiId = pathList[pathList.length-2]
    const slideImageName = pathList[pathList.length-1]
    console.log(wsiId)


    return (
        <>
            <OpenSeadragonViewer data={data} annotations={annotations} setAnnotations={setAnnotations} width="1980px" height="800px"/>
        </>
    )
}