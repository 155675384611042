import {useLocation} from "react-router-dom";
import {Card, Grid, Heading, Link, Table, TableBody, TableCell, TableRow, useTheme} from "@aws-amplify/ui-react";

export const BreadcrumbList = () => {

    const { tokens } = useTheme();
    const location = useLocation()
    const locationPath = location.pathname.split("/")

    console.log(locationPath.length)

    let breadcrumbList = []
    for (let i = 1; i <= locationPath.length; i++) {
        breadcrumbList.push(locationPath.slice(0, i).join("/"))
    }
    console.log(breadcrumbList)

    if (breadcrumbList.length === 2 && breadcrumbList[0] === "" && breadcrumbList[1] === "/") {
        breadcrumbList.pop()
    }


    return (
        <>
            <>
                <Grid
                    columnGap="0.5rem"
                    rowGap="0.5rem"
                    templateColumns="10fr 10fr"
                    templateRows="1fr"
                    backgroundColor={tokens.colors.blue[15]}
                >
                    <Card>
                        <Table highlightOnHover={false} variabion="bordered">
                            <TableBody>
                                <TableRow>
                                    <TableCell as="th">パス</TableCell>
                                    <TableCell>
                                        {
                                            <>
                                                {breadcrumbList.map((v, i) => (
                                                    <>
                                                        &gt; <Link key={i} href={v === "" ? "/" : v}>{v === "" ? "/" : v}</Link>
                                                    </>
                                                ))}
                                            </>
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Card>
                </Grid>
            </>
        </>
    )
}