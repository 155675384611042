import React, {useEffect, useState} from "react";
import {APIServerConfiguration} from "../../config/APIServerConfiguration";
import {Outlet, useLocation, useParams} from "react-router-dom";
import {Link, Card, Grid, Table, TableBody, TableRow, TableCell} from "@aws-amplify/ui-react";
import {Auth} from "aws-amplify";

export const SlideImageList = () => {

    const host = APIServerConfiguration.host
    const location = useLocation()

    console.log(host)
    console.log(location.pathname)
    let params = useParams()
    let path = location.pathname
    let wsiId = params.id

    const [data, setData] = useState()
    sessionStorage.removeItem('originatingURL');
    useEffect(() => {
        let url = APIServerConfiguration.host + '/v1/slides' + path
        Auth.currentSession().then(data => {
            const accessToken = data.getAccessToken().getJwtToken();
            return fetch(url, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }).then(response => response.json()).then(setData).catch(console.error)
        })
    }, [])
    //     fetch(url)
    //         .then(response => response.json())
    //         .then(setData)
    //         .catch(console.error)
    // }, [])


    if (data) {
        console.log(data)
        return (
            <Grid
                columnGap="0.5rem"
                rowGap="0.5rem"
                templateColumns="6fr 10fr"
                templateRows="1fr 3fr 1fr"
            >
                <Card>
                    <Table highlightOnHover={true} variation="bordered">
                        <TableBody>
                            <TableRow>
                                <TableCell as="th">WSI ID</TableCell>
                                <TableCell>{wsiId}</TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell as="th">イメージ一覧</TableCell>
                                <TableCell key="imageList">
                                    <div key="tableCell">
                                        {
                                            data['names'].map((item) => {
                                                return (
                                                    <div key={item}>
                                                        <Link key={item} href={location.pathname+"/"+item}>{item}</Link>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
                <Card>
                    <Outlet />
                </Card>
            </Grid>
        )
    }



    return (
        <Grid
            columnGap="0.5rem"
            rowGap="0.5rem"
            templateColumns="1fr 20fr"
            templateRows="1fr 3fr 1fr"
        >
            <Card>
              <ul>
              </ul>
            </Card>
            <Card>
                <Outlet />
            </Card>
        </Grid>
    )
}