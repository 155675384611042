import {OpenSeadragonEvent} from "../OpenSeadragonEvent";

export const clearHandlers = (viewer) => {
    if(viewer) {
        viewer.removeAllHandlers(OpenSeadragonEvent.CanvasClick);
        viewer.removeAllHandlers(OpenSeadragonEvent.CanvasPress);
        viewer.removeAllHandlers(OpenSeadragonEvent.CanvasRelease);
        viewer.removeAllHandlers(OpenSeadragonEvent.CanvasDrag);
        viewer.removeAllHandlers(OpenSeadragonEvent.CanvasDragEnd);
        viewer.setMouseNavEnabled(true);
    }
}