import React from "react";
import {Link, Outlet, useLocation} from "react-router-dom";

export const Page404NotFound = () => {
    let location = useLocation()
    return (
        <div>
            <h1>Resource not found at {location.pathname}</h1>
        </div>
    )
}

export const About = () => {
    return (
        <div>
            <h1>[About]</h1>
            <Link to="services">Services</Link>
            <Link to="history">History</Link>
            <Link to="location">Location</Link>
            <Outlet />
        </div>
    )
}

export const Services = () => {
    return (
        <section>
            <h2>Our Services</h2>
            <p>
                われわれのサービスはこんな感じなんですよ。
                割にすごないですか?
            </p>

        </section>
    )
}

export const History = () => {
    return(
        <section>
            <h2>Our History</h2>
            <p>
               われわれの歴史ってこんな感じでして。
               すごいですよね？
            </p>
        </section>
    )
}

export const Location = () => {
    return(
        <section>
            <h2>Our Location</h2>
            <p>
                実は月面の裏側にブランチオフィスがある
            </p>
        </section>
    )
}

export const Events = () => {
    return (
        <div>
            <h1>[Events]</h1>
        </div>
    )
}

export const Products = () => {
    return (
        <div>
            <h1>[Products]</h1>
        </div>
    )
}

export const Contact = () => {
    return (
        <div>
            <h1>[Contact]</h1>
        </div>
    )
}