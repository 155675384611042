import './App.css';
import React from "react";
import {Amplify, Hub, Auth} from "aws-amplify";
import {useEffect, useState} from "react";
import {amplifyConfig} from "./config/AmplifyConfiguration";
import '@aws-amplify/ui-react/styles.css';
import {RouteSettings} from "./components/RouteSettings";
import {PageHeader} from "./components/PageHeader";
import {Button, Heading, useTheme} from "@aws-amplify/ui-react";
import {BreadcrumbList} from "./components/BreadcrumbList";
import {Navigate, redirect, useNavigate} from "react-router-dom";
import {APIServerConfiguration} from "./config/APIServerConfiguration";

Amplify.configure(amplifyConfig)

function App() {

    const navigate = useNavigate()

    Amplify.configure(amplifyConfig)
    const [user, setUser] = useState(null)


    console.log('url:' + window.location.href)
    const loginRegex = APIServerConfiguration.getLoginRedirectRegexWithCodeParam()
    const loginRegex2 = APIServerConfiguration.getLoginRedirectRegex()
    console.log(loginRegex)
    const url = sessionStorage.getItem('originatingURL');
    if (window.location.href.match(loginRegex)){
        console.log('match')
        console.log('originatingURL: ' + url)
    } else if ( window.location.href.match(loginRegex2)){
        console.log('match')
        console.log('originatingURL: ' + url)
    } else if ( window.location.href === sessionStorage.getItem('originatingURL')) {
        console.log('window.location.href and originatingURL value sessionStorage have the same value')
        //console.log('remove originatingURL from sessionStorage')
    } else {
        console.log("not match")
        console.log(window.location.href)
        sessionStorage.setItem('originatingURL', window.location.href)
    }
    if (url && window.location.href !== url) {
        navigate(url.replace(RegExp('^https?:\\/\\/' + APIServerConfiguration.nodeHost + '\\/', "g"), ""));
    }


    const getUser = async() => {
        try {
            const userData = await Auth.currentAuthenticatedUser();
            /* デバッグ用 */
            Auth.currentSession().then((data) => {
                console.log(`token: ${data.getIdToken().getJwtToken()}`);
            });
            console.log(userData);
            /* デバッグ用ここまで */
            return userData;
        } catch (e) {
            console.log('Not signed in')
            await Auth.federatedSignIn();
        }
    }

    useEffect(() => {
        Hub.listen('auth', ({ payload: {event, data}})=> {
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    getUser().then(userData => setUser(userData));
                    break;
                case 'signOut':
                    setUser(null);
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    console.log('Sign in failure', data);
                    break;
                default:
            }
        });

        getUser().then(userData => setUser(userData));
    }, []);


    const { tokens } = useTheme();
    return user ? (
        /* サインイン済みの場合*/
        <div>
            <PageHeader userName={user.username}/>
            <RouteSettings />
        </div>
    ) : (
        <>
            <Heading level={1} backgroundColor={tokens.colors.blue[20]}>BonBon Viewer サインイン</Heading>
        </>
    )
}

export default App;