import {useModal} from "react-hooks-use-modal";
import {Button, TextAreaField, View} from "@aws-amplify/ui-react";
import {useState} from "react";
import {AnnotationType} from "../OpenSeadragonAnnotationType";
import {APIServerConfiguration} from "../../../../../config/APIServerConfiguration";
import {Auth} from "aws-amplify";

const createArrowAnnotation = (viewer, annotationId, text, annotations, setAnnotations) => {

    const a = document.getElementById(annotationId);
    if(a) {
        console.log(`x1: ${a.getAttribute("x1")}`);
        console.log(`x2: ${a.getAttribute("x2")}`);
        console.log(`y1: ${a.getAttribute("y1")}`);
        console.log(`y2: ${a.getAttribute("y2")}`);
        console.log(`annotationId: ${annotationId}`);
        const annotation = {
            type: AnnotationType.Arrow,
            annotationId: annotationId,
            startX: parseFloat(a.getAttribute("x1")),
            startY: parseFloat(a.getAttribute("y1")),
            endX: parseFloat(a.getAttribute("x2")),
            endY: parseFloat(a.getAttribute("y2")),
            annotationText: text ? text : "",
            slideId: annotations.slideId
        }
        annotations.annotations.push(annotation);
        setAnnotations({
            slideId: annotations.slideId,
            annotations: annotations.annotations
        });
        a.remove();
        return annotation;
    }
}

const saveAnnotation = (annotation) => {
    const postAnnotationUrl = `${APIServerConfiguration.host}/v1/slides/${annotation.slideId}/annotation`;

    Auth.currentSession().then(data => {
        const accessToken = data.getAccessToken().getJwtToken();
        return fetch(postAnnotationUrl, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(annotation)
        }).then(response => response.json()).catch(error => console.log(error));
    });
}

export const useOpenSeadragonCreateArrowAnnotationModal = ({ viewer, annotations, setAnnotations}) => {

    const [CreateModal, openCreateModalSource, closeCreateModal] = useModal();
    let annotationText = ""
    const [annotationId, setAnnotationId] = useState("");

    const clickCreateAnnotationButton = (event) => {
        const a = createArrowAnnotation(viewer, annotationId, annotationText, annotations, setAnnotations);
        saveAnnotation(a);
        closeCreateModal();
    }

    const clickCancelCreateAnnotationButton = (event) => {
        const a = document.getElementById(annotationId);
        if(a) {
            a.remove();
        }
        closeCreateModal();
    }

    const updateAnnotationText = (event) => {
        annotationText = event.target.value;
        console.log(annotationText);
    }

    const openCreateAnnotationModal = (id) => {
        setAnnotationId(id);
        openCreateModalSource();
    }

    const CreateAnnotationModal = () => {
        return (
            <CreateModal>
                <View
                    ariaLabel="アノテーション削除確認"
                    backgroundColor="var(--amplify-colors-white)"
                    borderRadius="6px"
                    border="1px solid var(--amplify-colors-black)"
                    boxShadow="3px 3px 5px 6px var(--amplify-colors-neutral-60)"
                    color="var(--amplify-colors-blue-60)"
                    maxWidth="100%"
                    padding="1rem"
                >
                    <form>
                        {`アノテーション${annotationId}の作成`}
                        <TextAreaField
                            label={`内容`}
                            rows="3"
                            defaultValue=""
                            onChange={updateAnnotationText}
                        />
                        <Button onClick={(event) => {
                            clickCreateAnnotationButton(event)}}>作成</Button>
                        <Button onClick={(event) => {
                            clickCancelCreateAnnotationButton(event)}}>キャンセル</Button>
                    </form>
                </View>
            </CreateModal>
        )
    }


    return [CreateAnnotationModal, openCreateAnnotationModal, closeCreateModal];
}