import OpenSeadragon from 'openseadragon';
export const calculateRectangleInfo = (viewer, data, width, height) => {

    console.log(data);
    const mppX = Number(data['Image']['MppX'])
    const mppY = Number(data['Image']['MppY'])
    console.log(mppX)
    console.log(mppY)

    const pixelsPerMeterX = 1000000 / mppX
    const pixelsPerMeterY = 1000000 / mppY
    console.log(`ppmX: ${pixelsPerMeterX}`)
    console.log(`ppmY: ${pixelsPerMeterY}`)

    const vp = viewer.viewport;
    const tiledImage = viewer.world.getItemAt(0);
    const getTiledImageViewportToImageZoom = (tiledImage, viewPortZoom) => {
        let ratio = tiledImage._scaleSpring.current.value *
            tiledImage.viewport._containerInnerSize.x /
            tiledImage.source.dimensions.x;
        return ratio * viewPortZoom
    }

    const getValueAndUnit = (w, h) => {

        let smaller = w > h ? h : w; // 小さい方を選ぶ
        console.log(smaller)

        return {
            width: 1000 * w,
            height: 1000 * h,
            unit: "mm"
        }
    }

    const zoom = getTiledImageViewportToImageZoom(tiledImage, vp.getZoom(true));
    const currentPpmX = pixelsPerMeterX * zoom;
    const currentPpmY = pixelsPerMeterY * zoom;
    console.log(`currentPpmX: ${currentPpmX}`)
    console.log(`currentPpmY: ${currentPpmY}`)

    console.log(width)
    console.log(height)
    const physicalWidthWithMeter = width / currentPpmX
    const physicalHeightWithMeter = height / currentPpmY
    console.log(`physicalWidthWithMeter: ${physicalWidthWithMeter}`)
    console.log(`physicalHeightWithMeter: ${physicalHeightWithMeter}`)

    const rect = getValueAndUnit(physicalWidthWithMeter, physicalHeightWithMeter);

    console.log(rect)

    return rect
}