
export const TextAnnotationGreen = "#17B169" // Carlsberg Green

export const DefaultStrokeWidth = 0.002;

export const DefaultTextSize = 0.0125;

export const AnnotationTextShadow = `1px 1px 0 #FFF, -1px -1px 0 #FFF,
              -1px 1px 0 #FFF, 1px -1px 0 #FFF,
              0px 1px 0 #FFF,  0-1px 0 #FFF,
              -1px 0 0 #FFF, 1px 0 0 #FFF`

export const AnnotationRectBorder = `4px solid ${TextAnnotationGreen}`

export const RectStyle = {
    border: `4px solid #17B169`,
    background: "transparent",
    zIndex: "1000",
}

export const TextStyle = {
    color: "#17B169",
    position: "relative",
    fontSize: "1.5em",
    textShadow: `1px 1px 0 #FFF, -1px -1px 0 #FFF,
              -1px 1px 0 #FFF, 1px -1px 0 #FFF,
              0px 1px 0 #FFF,  0-1px 0 #FFF,
              -1px 0 0 #FFF, 1px 0 0 #FFF`,
    top: "-2em",
    whiteSpace: "nowrap",
    wordBreak: "keep-all",
    overflow: "visible",
}
