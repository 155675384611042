import {AnnotationType} from "./OpenSeadragonAnnotationType";
import {OpenSeadragonLengthAnnotation} from "./OpenSeadragonLengthAnnotation";
import {useEffect} from "react";
import {clearHandlers} from "./util/OpenSeadragonUtil";
import {OpenSeadragonEvent} from "./OpenSeadragonEvent";
import {DefaultStrokeWidth, TextAnnotationGreen, TextStyle} from "./OpenSeadragonAnnotationConstants";
import * as d3 from "d3";
import {calculateRectangleInfo} from "./OpenSeadragonCalculateRectangleInfo";
import OpenSeadragon from "openseadragon";
import {Auth} from "aws-amplify";
import {APIServerConfiguration} from "../../../../config/APIServerConfiguration";


const drawLengthAnnotation = (viewer, data, startPoint, endPoint, annotations, setAnnotations, annotationId) => {
    const overlay = viewer.svgOverlay();
    const g = overlay.node();

    const start = viewer.viewport.pointFromPixel(startPoint);
    const end = viewer.viewport.pointFromPixel(endPoint);

    const oldLine = document.getElementById(annotationId);
    if(oldLine) {
        oldLine.remove();
    }

    const oldLength = document.getElementById(`${annotationId}/div`);
    if(oldLength) {
        console.log("古い長さ測定のリソースを削除")
        viewer.removeOverlay(oldLength);
        oldLength.remove();
    }
    viewer.removeOverlay(`${annotationId}/div`);

    const line = d3.select((g)).append("line")
        .style("stroke", TextAnnotationGreen)
        .style("stroke-width", `${DefaultStrokeWidth/viewer.viewport.getZoom(false)}`)
        .attr("id", annotationId)
        .attr("x1", start.x)
        .attr("x2", end.x)
        .attr("y1", start.y)
        .attr("y2", end.y);

    const width = Math.abs(startPoint.x - endPoint.x);
    const height = Math.abs(startPoint.y - endPoint.y);
    const rectangleInfo = getRectangleInfo(viewer, data, startPoint, endPoint);
    const [length, unit] = getLength(rectangleInfo);
    console.log(`length: ${length} ${unit}`);

    const lengthDiv = createLengthDiv(`${annotationId}/div`, getDegree(startPoint, endPoint));
    const lengthText = document.createTextNode(`${length.toPrecision(4)} ${unit}`);
    lengthDiv.appendChild(lengthText);

    const degree = getDegree(startPoint, endPoint);
    const sp = viewer.viewport.pointFromPixel(startPoint);
    const ep = viewer.viewport.pointFromPixel(endPoint);
    const overlayLocation = new OpenSeadragon.Rect(sp.x, sp.y, Math.abs(ep.x - sp.x), Math.abs(ep.y - sp.y));
    viewer.addOverlay(lengthDiv, overlayLocation);

}

const createLengthDiv = (id, degree) => {
   const div = document.createElement('div');
    div.id = id;
    div.style.color = TextStyle.color;
    div.style.position = TextStyle.position;
    div.style.fontSize = TextStyle.fontSize;
    div.style.textShadow = TextStyle.textShadow;
    div.style.top = "0px";
    div.style.whiteSpace = TextStyle.whiteSpace;
    div.style.wordBreak = TextStyle.wordBreak;
    div.style.overflow = TextStyle.overflow;
    div.style.transformOrigin = "top left";
    return div;
}

const addLengthAnnotation = (viewer, data, startPoint, endPoint, annotations, setAnnotations, annotationId) => {
    const oldLine = document.getElementById(annotationId);
    if(oldLine) {
        oldLine.remove();
    }

    const oldLength = document.getElementById(`${annotationId}/div`);
    if(oldLength) {
        viewer.removeOverlay(oldLength);
        oldLength.remove();
    }
    // viewer.removeOverlay(`${annotationId}/div`);

    const degree = getDegree(startPoint, endPoint);
    console.log(`角度: ${degree}`);
    const rectangleInfo = getRectangleInfo(viewer, data, startPoint, endPoint);
    const [length, unit] = getLength(rectangleInfo);

    const sp = viewer.viewport.pointFromPixel(startPoint);
    const ep = viewer.viewport.pointFromPixel(endPoint);

    const annotation = {
        annotationId: annotationId,
        type: AnnotationType.Length,
        startX: sp.x,
        startY: sp.y,
        endX: ep.x,
        endY: ep.y,
        degree: degree,
        length: length,
        unit: unit,
        slideId: annotations.slideId,
    }

    annotations.annotations.push(annotation);
    setAnnotations(annotations);

    // TODO サーバーサイドで永続化すること
    const postAnnotationUrl = APIServerConfiguration.host + '/v1/slides/' + annotations.slideId + '/annotation';
    Auth.currentSession().then(data => {
        const accessToken = data.getAccessToken().getJwtToken();
        return fetch(postAnnotationUrl, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(annotation)
        }).then(response => response.json())
            .then((j) => {
                console.log(j);
            })
    })
}


const getRectangleInfo = (viewer, data, startPoint, endPoint) => {
    const width = Math.abs(startPoint.x - endPoint.x);
    const height = Math.abs(startPoint.y - endPoint.y);
    const rectangleInfo = calculateRectangleInfo(viewer, data, width, height);
    return rectangleInfo;
}

const getLength = (rectangleInfo) => {
    const length = Math.sqrt(Math.pow(rectangleInfo['width'], 2) + Math.pow(rectangleInfo['height'], 2));
    return [length, rectangleInfo.unit];
}


const getDegree = (startPoint, endPoint) => {
    const radian = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);
    const degree = radian * 180 / Math.PI;
    return degree;
}

export const OpenSeadragonLengthAnnotations = ({viewer, data, annotations, setAnnotations, rotate, controllerValue, isAnnotationVisible, reset}) => {

    let startPoint = {x: 0, y: 0};
    let annotationId = "";
    const pressEvent = (event) => {
        event.preventDefaultAction = true;
        startPoint = event.position;
        annotationId = crypto.randomUUID();
    };

    let endPoint = {x: 0, y: 0};
    const releaseEvent = (event) => {
        event.preventDefaultAction = true;
        endPoint = event.position;
        // drawLengthAnnotation(viewer, data, startPoint, endPoint, annotations, setAnnotations, annotationId);
        addLengthAnnotation(viewer, data, startPoint, endPoint, annotations, setAnnotations, annotationId);
        if(reset) {
            reset("");
        }
    }

    const draggingEvent = (event) => {
        event.preventDefaultAction = true;
        endPoint = event.position;
        drawLengthAnnotation(viewer, data, startPoint, endPoint, annotations, setAnnotations, annotationId);
    }

    const dragEnd = (event) => {
        event.preventDefaultAction = true;
        endPoint = event.position;
    }

    useEffect(() => {
        switch(controllerValue) {
           case AnnotationType.Length:
               console.log("長さ測定アノテーションの作成だよ")
               clearHandlers(viewer);
               viewer.addOnceHandler(OpenSeadragonEvent.CanvasClick, (event) => {event.preventDefaultAction = true;});
               viewer.addOnceHandler(OpenSeadragonEvent.CanvasPress, pressEvent);
               viewer.addOnceHandler(OpenSeadragonEvent.CanvasRelease, releaseEvent)
               viewer.addHandler(OpenSeadragonEvent.CanvasDrag, draggingEvent)
               viewer.addOnceHandler(OpenSeadragonEvent.CanvasDragEnd, dragEnd);

               // viewer.addOnceHandler(OpenSeadragonEvent.CanvasRelease, (event) => {
               //     startPoint = null;
               //     endPoint = null;
               //     annotationId = null;
               //     if(reset) {
               //         reset("");
               //     }
               // })
               break;
        }
    }, [controllerValue]);

    useEffect(() => {
        // svg-overlayのレイヤで表示制御
        const svgOverlay = document.getElementById("svg-overlay");
        if(svgOverlay) {
            svgOverlay.style.visibility = isAnnotationVisible ? "visible" : "hidden";
        }
    }, [isAnnotationVisible]);

    return viewer && annotations ? (
        <>
            {
                annotations.annotations.filter((annotation) => annotation['type'] === AnnotationType.Length)
                    .map((annotation) =>
                        <OpenSeadragonLengthAnnotation
                            key={annotation.annotationId}
                            annotationId={annotation.annotationId}
                            viewer={viewer}
                            annotation={annotation}
                            annotations={annotations}
                            setAnnotations={setAnnotations}
                            rotate={rotate}
                            isAnnotationVisible={isAnnotationVisible}
                            controllerValue={controllerValue}
                            setControllerValue={reset}
                        />
                    )
            }
        </>
    ) : (<></>)
}