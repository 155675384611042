import {Button, Card, Grid, Link, Table, TableBody, TableCell, TableHead, TableRow} from "@aws-amplify/ui-react";
import {Link as ReactRouterLink} from "react-router-dom";
import {APIServerConfiguration} from "../../../config/APIServerConfiguration";
import {Auth} from "aws-amplify";


export const AdminSlideList = (props) => {

    if(props.slides === null || props.slides === undefined ){
        return (
            <>
            </>)
    }

    const slides = props.slides;

    const handleDelete = (event) => {
        console.log("clicked")
        console.log(event)
        console.log(event.currentTarget.id)
        let isDelete = window.confirm(event.currentTarget.id+`を削除しますか`)
        let url = APIServerConfiguration.wsImageDelete + event.currentTarget.id
        if(isDelete) {
            Auth.currentSession().then((data) => {
                console.log("削除します")
                // event.currentTarget.idにWSI IDが格納されている
                const method = "DELETE"
                console.log(url)

                fetch(url, {
                    method: method,
                    headers: {
                        Authorization: `Bearer ${data.getIdToken().getJwtToken()}`
                    }
                }).then(response => {
                        if (response.status === 200) {
                            return response.json()
                        } else {
                            throw new Error(response.statusText)
                        }
                    }
                )
                    .then((result) => {
                        console.log(result)
                        window.alert(result.wsiId + `の削除が完了しました`)
                    }).catch(err => {
                    window.alert(event.currentTarget.id + `の削除に失敗しました`)
                    console.error(event.currentTarget.id + `の削除に失敗しました`)
                    console.error(err)
                })
            })
        }
        console.log("削除しません")
    }

    return (
        <>
            <Table highlightOnHover={true} variation="bordered">
                <TableHead>
                    <TableRow>
                        <TableCell as="th">WSI ID</TableCell>
                        <TableCell as="th">CASE ID</TableCell>
                        <TableCell as="th">Status</TableCell>
                        <TableCell as="th">操作</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                { slides.map((item) => {
                    return(
                        <TableRow>
                            <TableCell>
                                <ReactRouterLink to={`/` + item.wsiId} component={Link}>{item.wsiId}</ReactRouterLink>
                            </TableCell>
                            <TableCell>{item.caseId}</TableCell>
                            <TableCell>{item.status}</TableCell>
                            <TableCell>
                                <Button variation="warning"
                                        id={item.wsiId}
                                        onClick={handleDelete}>削除</Button>
                            </TableCell>
                        </TableRow>
                    )
                    })

                }
                </TableBody>
            </Table>
        </>
    )
}