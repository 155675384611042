import {useState} from "react";
import {APIServerConfiguration} from "../../../config/APIServerConfiguration";
import {Button, Card, Divider, Grid, Link, TextField} from "@aws-amplify/ui-react";
import {AdminSlideList} from "./AdminSlideList";
import {Link as ReactRouterLink} from "react-router-dom";
import {Auth} from "aws-amplify";


export const AdminSlideSearchForm = () => {

    const [slides, setSlides] = useState();
    const [searchWord, setSearchWord] = useState("");
    let url = APIServerConfiguration.adminSearchSlide;


    const handleSubmit = (event) => {
        event.preventDefault()

        if(searchWord === null || searchWord.replaceAll(" ", "") === "") {
            alert("WSI IDを入力してください");
            return
        }

        let urlWithParameter = url + '?begins_with=' + searchWord
        Auth.currentSession().then(data => {
            const accessToken = data.getAccessToken().getJwtToken();
            return fetch(urlWithParameter, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            }).then(response => response.json()).then(setSlides).catch(console.error)
        })
        // fetch(urlWithParameter)
        //     .then(response => response.json())
        //     .then(setSlides)
        //     .catch(console.error)
    }

    const handleToTop = (event) => {
        sessionStorage.removeItem('originatingURL')
    }

    return (
        <>
            <Grid
                columnGap="0.5rem"
                rowGap="0.5rem"
                templateColumns="5fr 20fr"
                templateRows="1fr 3fr 1fr"
            >
                <Card
                    columnStart="1"
                    columnEnd="3"
                >
                    <ReactRouterLink to={`/`} component={Link} onClick={handleToTop} n>トップへ</ReactRouterLink>
                </Card>
                <form onSubmit={handleSubmit}>
                        <Card
                            columnStart="1"
                            columnEnd="2"
                        >
                            <TextField label="WSI ID"
                                       placeholder="検索対象のWSI IDを入力してください"
                                       onChange={(event) => setSearchWord(event.target.value)}
                            />
                        </Card>
                        <Card
                            columnStart="1"
                            columnEnd="3"
                        >
                            <Button onClick={handleSubmit}>検索</Button>
                        </Card>
                </form>
                    <AdminSlideList slides={slides}/>
            </Grid>
        </>
    )
}