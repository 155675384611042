import {
    AnnotationTextShadow,
    DefaultStrokeWidth,
    DefaultTextSize,
    TextAnnotationGreen,
    TextStyle
} from "./OpenSeadragonAnnotationConstants";
import {useEffect} from "react"
import * as d3 from "d3";
import OpenSeadragon from "openseadragon";
import {AnnotationType} from "./OpenSeadragonAnnotationType";
import {APIServerConfiguration} from "../../../../config/APIServerConfiguration";
import {Auth} from "aws-amplify";
import {useOpenSeadragonDeleteLengthAnnotationModal} from "./length/OpenSeadragonDeleteLengthAnnotationModal";



const addTextNode = (viewer, annotation) => {

    const checkIfTextNodeExists = document.getElementById(`${annotation['annotationId']}/div`);
    if(checkIfTextNodeExists) {
        return;
    }

    const rectangleDiv= document.createElement("div");
    rectangleDiv.id = `${annotation['annotationId']}/div`


    // console.log(`rotate(${parseInt(degree)}deg)`);
    // rectangleDiv.style.display = "block";
    rectangleDiv.style.color = TextStyle.color;
    rectangleDiv.style.position = TextStyle.position;
    rectangleDiv.style.fontSize = TextStyle.fontSize;
    rectangleDiv.style.textShadow = TextStyle.textShadow;
    rectangleDiv.style.top = "0px";
    rectangleDiv.style.whiteSpace = TextStyle.whiteSpace;
    rectangleDiv.style.wordBreak = TextStyle.wordBreak;
    rectangleDiv.style.overflow = TextStyle.overflow;
    rectangleDiv.style.transformOrigin = "top left";
    rectangleDiv.style.zIndex = "1000";
    rectangleDiv.style.cursor = "default";
    if(annotation['degree'] < 90) {
        rectangleDiv.style.transform = `rotate(${parseInt(annotation['degree'])}deg)`;
    } else {
        rectangleDiv.style.transform = `rotate(${parseInt(annotation['degree'] + 180)}deg)`;
    }

    const rectangleLocation = new OpenSeadragon.Rect(
        // 傾きが -90 ~ 90度の場合は、開始点から長さを表示
        // 傾きが 90 ~ 180度、または-90 ~ -180度の場合は、終了点から長さを表示
        Math.abs(annotation['degree']) < 90 ? annotation['startX'] : annotation['endX'],
        Math.abs(annotation['degree']) < 90 ? annotation['startY'] : annotation['endY'],
        Math.abs(annotation['endX'] - annotation['startX']),
        Math.abs(annotation['endY'] - annotation['startY']))

    const textNode = document.createTextNode(`${annotation['length'].toPrecision(4)} ${annotation['unit']}`);
    rectangleDiv.appendChild(textNode);

    if (viewer) {
        viewer.addOverlay(rectangleDiv, rectangleLocation);
    }
}

const updateAnnotation = (viewer, annotation) => {

    const line = document.getElementById(annotation['annotationId']);
    if(viewer && line) {
        line.style.strokeWidth = `${DefaultStrokeWidth/viewer.viewport.getZoom(false)}`;
    }
}

const addAnnotation = (viewer, annotation) => {
    const line = document.getElementById(annotation['annotationId']);
    if(viewer && !line) {
        const overlay = viewer.svgOverlay();
        const g = overlay.node();
        const d3Line = d3.select((g)).append("line")
            .style("stroke", TextAnnotationGreen)
            .style("stroke-width", `${DefaultStrokeWidth/viewer.viewport.getZoom(false)}`)
            .attr("id", annotation['annotationId'])
            .attr("x1", annotation['startX'])
            .attr("x2", annotation['endX'])
            .attr("y1", annotation['startY'])
            .attr("y2", annotation['endY'])
    }
}

// 移動したアノテーションを保存する。サーバサイドの更新と、stateを更新する。
export const saveAnnotation = (annotation, annotations, setAnnotations) => {
    const baseUrl = `${APIServerConfiguration.host}/v1/slides${window.location.pathname}`;
    const specificAnnotationUrl = `${baseUrl}/annotation/${annotation.annotationId}`;

    Auth.currentSession().then(data => {
        const accessToken = data.getAccessToken().getJwtToken();
        return fetch(specificAnnotationUrl,
            {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(annotation)
            })
            .then(response => response.json())
            .catch(error => console.log(error))
    })

    const newAnnotations = annotations.annotations.map((a) => {
        if(a.annotationId === annotation.annotationId) {
            return { ...a,
                startX: annotation.startX,
                startY: annotation.startY,
                endX: annotation.endX,
                endY: annotation.endY,
            };
        }
        return a;
    });
    setAnnotations({
        slideId: annotations.slideId,
        annotations: newAnnotations
    });
}

const updateVisibility = (viewer, annotation, isAnnotationVisible) => {
    const line = document.getElementById(annotation['annotationId']);
    if(viewer && line) {
        if(isAnnotationVisible) {
            line.style.visibility = "visible";
        } else {
            line.style.visibility = "hidden";
        }
    }
    const text = document.getElementById(`${annotation['annotationId']}/div`);
    if(text) {
        if(isAnnotationVisible) {
            text.style.visibility = "visible";
        } else {
            text.style.visibility = "hidden";
        }
    }
}

export const getRectangleLocation = (annotation) => {
    return new OpenSeadragon.Rect(
        // 傾きが -90 ~ 90度の場合は、開始点から長さを表示
        // 傾きが 90 ~ 180度、または-90 ~ -180度の場合は、終了点から長さを表示
        Math.abs(annotation['degree']) < 90 ? annotation['startX'] : annotation['endX'],
        Math.abs(annotation['degree']) < 90 ? annotation['startY'] : annotation['endY'],
        Math.abs(annotation['endX'] - annotation['startX']),
        Math.abs(annotation['endY'] - annotation['startY']));
}

export const OpenSeadragonLengthAnnotation = ({annotationId, viewer, annotation, annotations, setAnnotations, rotate, isAnnotationVisible, controllerValue, setControllerValue}) => {


    const [DeleteLengthAnnotationModal, openDeleteAnnotationModal, closeDeleteAnnotationModal] = useOpenSeadragonDeleteLengthAnnotationModal(
        {annotation, annotations, setAnnotations, setControllerValue, viewer})

    const deleteAnnotation = (event) => {
        event.preventDefault();
        openDeleteAnnotationModal();
    }

    const setEventFunction = (id, f) => {
        const line = document.getElementById(id);
        if(line) {
            line.onclick = f;
        }
        const text = document.getElementById(`${id}/div`);
        if(text) {
            console.log("セットアップできたよ");
            text.onclick = f;
        }
    }

    const updateZoom = (event) => {
        const zoom = viewer.viewport.getZoom(false);
        updateAnnotation(viewer, annotation);

        const rectangle = document.getElementById(`${annotationId}/div`)

        if(rectangle) {
            rectangle.style.transformOrigin = "top left";

            if (Math.abs(annotation['degree']) < 90) {
                // 傾きが -90度 ~ 90度の間の場合はそのまま処理
                const rotate = viewer.viewport.getRotation() % 360 + annotation['degree'];
                rectangle.style.transform = `rotate(${parseInt(rotate)}deg)`;
            } else {
                // 傾きが 90 ~ 180度または、-90 ~ -180度の場合は、180度回転させる
                const rotate = viewer.viewport.getRotation() % 360 + annotation['degree'] + 180;
                rectangle.style.transform = `rotate(${parseInt(rotate)}deg)`;
            }
            rectangle.style.width = "0px";
            rectangle.style.height = "0px";
        }
    }

    useEffect(() => {
        if(viewer) {
            viewer.addHandler('zoom', updateZoom);
            viewer.addHandler("animation", updateZoom);
            viewer.addHandler("update-viewport", () => {
                setTimeout(updateZoom, 0.01);
            });
        }
        return () => {viewer.removeHandler('zoom', updateZoom); viewer.removeHandler('animation', updateZoom)};
    }, [])

    useEffect(() => {
        updateVisibility(viewer, annotation, isAnnotationVisible);
    }, [isAnnotationVisible])

    // updateAnnotation(viewer, annotation);
    addAnnotation(viewer, annotation);
    addTextNode(viewer, annotation);

    const clearEventListeners = (id) => {
        const element = document.getElementById(id);
        if(element) {
            element.onclick = null;
            element.onmousedown = null;
        }

        const elementText = document.getElementById(`${id}/div`);
        if (elementText) {
            elementText.onclick = null;
            elementText.onmousedown = null;
            elementText.parentNode.parentNode.onmousemove = null;
            elementText.parentNode.parentNode.onmouseup = null;
        }
    }


    let mousePosition = {x: 0, y:0}
    let selectedAnnotationId = null;
    let lineDelta = {x: 0, y: 0};
    const startMoveElement = (event) => {
        event.preventDefault();
        selectedAnnotationId = `${event.target.id}`.replace("/div", "");
        console.log(`selectedAnnotationId: ${selectedAnnotationId}`);

        mousePosition = {
            x: event.clientX,
            y: event.clientY
        }
        lineDelta = {
            x: annotation.endX - annotation.startX,
            y: annotation.endY - annotation.startY
        }


        console.log(mousePosition);
        console.log(lineDelta);

        const line = document.getElementById(selectedAnnotationId);
        const element = document.getElementById(`${selectedAnnotationId}/div`);

        if(element) {
            element.parentNode.parentNode.onmousemove = (event) => {
                mousePosition = {
                    // x: event.offsetX,
                    // y: event.offsetY
                    x: event.clientX,
                    y: event.clientY - 50
                }
                // if (event.target.id === "svg-overlay") {
                    const mousePoint = viewer.viewport.pointFromPixel(new OpenSeadragon.Point(mousePosition.x, mousePosition.y));

                    // 直接要素をいじる
                    line.setAttribute('x1', `${mousePoint.x}`);
                    line.setAttribute('y1', `${mousePoint.y}`);
                    line.setAttribute('x2', `${mousePoint.x + lineDelta.x}`);
                    line.setAttribute('y2', `${mousePoint.y + lineDelta.y}`);


                    annotation.startX = mousePoint.x;
                    annotation.startY = mousePoint.y;
                    annotation.endX = mousePoint.x + lineDelta.x;
                    annotation.endY = mousePoint.y + lineDelta.y;

                    const rectangleLocation = getRectangleLocation(annotation);
                    viewer.updateOverlay(element, rectangleLocation);
                // }
            }
            element.parentNode.parentNode.onmouseup = (event) => {
                mousePosition = {
                    x: event.clientX,
                    y: event.clientY - 50
                }
                console.log(mousePosition);
                const mousePoint = viewer.viewport.pointFromPixel(new OpenSeadragon.Point(mousePosition.x, mousePosition.y));
                console.log(mousePoint);

                // 直接要素をいじる
                line.setAttribute('x1', `${mousePoint.x}`);
                line.setAttribute('y1', `${mousePoint.y}`);
                line.setAttribute('x2', `${mousePoint.x + lineDelta.x}`);
                line.setAttribute('y2', `${mousePoint.y + lineDelta.y}`);


                annotation.startX = mousePoint.x;
                annotation.startY = mousePoint.y;
                annotation.endX = mousePoint.x + lineDelta.x;
                annotation.endY = mousePoint.y + lineDelta.y;

                const rectangleLocation = getRectangleLocation(annotation);
                viewer.updateOverlay(element, rectangleLocation);

                saveAnnotation(annotation, annotations, setAnnotations);

                element.parentNode.parentNode.onmousemove = null;
                element.parentNode.parentNode.onmouseup = null;
                setControllerValue("");
            }
        }

    }

    useEffect(() => {
        const annot = document.getElementById(annotationId);
        const annotText = document.getElementById(`${annotationId}/div`);
        switch (controllerValue) {
            case AnnotationType.Delete:
                console.log("長さ測定アノテーションでDeleteに設定したよ");
                setEventFunction(annotationId, deleteAnnotation);
                if (annot) { annot.style.cursor = "pointer"; }
                if (annotText) { annotText.style.cursor = "pointer"; }
                break;
            case AnnotationType.Move:
                console.log("長さ測定アノテーションでMoveに設定したよ")
                if (annot) {
                    annot.style.cursor = "grab";
                    annot.onmousedown = startMoveElement;
                }
                if (annotText) {
                    annotText.style.cursor = "grab"
                    annotText.onmousedown = startMoveElement;
                }
                break;
            default:
                if (annot) { annot.style.cursor = "default"; }
                if (annotText) { annotText.style.cursor = "default"; }
                setEventFunction(annotationId, null);
                clearEventListeners(annotationId);
                break;
        }
    }, [controllerValue]);

    return (
        <>
            <DeleteLengthAnnotationModal/>
        </>
    )
}