
import {Link as ReactRouterLink, Outlet} from "react-router-dom";
import {Link as AmplifyLink , Grid, Card, Heading} from "@aws-amplify/ui-react";
import {APIServerConfiguration} from "../../config/APIServerConfiguration";
import {useEffect, useState} from "react";
import {Auth} from "aws-amplify";

export const SlideList = () => {

    const [data, setData] = useState();
    let url = APIServerConfiguration.wsiImageList

    useEffect(() => {
        Auth.currentSession().then(data => {
            const accessToken = data.getAccessToken().getJwtToken();
            return fetch(url, {
                headers: {
                "Authorization": `Bearer ${accessToken}`
                }
            })
        }).then(response => response.json()).then(setData).catch(console.error)
    }, [])


    if (data) {
        return (
            <div>
                <Grid
                    columnGap="0.5rem"
                    rowGap="0.5rem"
                    templateColumns="5fr 20fr"
                    templateRows="1fr 3fr 1fr"
                >
                    <Card>
                        <Heading level={4}>スライド一覧</Heading>
                        <ul>
                            {data['WsiIds'].map((item) => {
                                return (
                                    <li key={item}>
                                        <ReactRouterLink to={item} component={AmplifyLink}>{item}</ReactRouterLink>
                                    </li>
                                )
                            })}
                        </ul>
                    </Card>
                    <Card>
                        <Outlet/>
                    </Card>
                </Grid>
            </div>
        )
    }

    return (
        <div>
            <Grid
                columnGap="0.5rem"
                rowGap="0.5rem"
                templateColumns="4fr 20fr"
                templateRows="1fr 3fr 1fr"
            >
                <Card>
                    <ul>
                    </ul>
                </Card>
                <Card>
                    <Outlet/>
                </Card>
            </Grid>
        </div>
    )
}