import {useEffect, useState} from "react";
import {OpenSeadragonEvent} from "./OpenSeadragonEvent";
import {AnnotationType} from "./OpenSeadragonAnnotationType";
import {useModal} from "react-hooks-use-modal";
import {Button, TextAreaField, View} from "@aws-amplify/ui-react";
import {AnnotationTextShadow, RectStyle, TextAnnotationGreen} from "./OpenSeadragonAnnotationConstants";
import OpenSeadragon from "openseadragon";
import {APIServerConfiguration} from "../../../../config/APIServerConfiguration";
import {Auth} from "aws-amplify";
import {appendRectangleAnnotation} from "./OpenSeadragonRectangleAnnotationsController";

export const OpenSeadragonFixedSizeRectangleAnnotation = ({viewer, data, annotations, setAnnotations, rotate, controllerValue, reset}) => {

    const FixedEdgeLength = Math.sqrt(2);
    const FixedEdgeLengthMicron = FixedEdgeLength * 1000; // マイクロメートル単位での縦幅、横幅

    const [annotation, setAnnotation] = useState({
        type: AnnotationType.Rectangle,
    });
    const [CreateAnnotationModal, openCreateAnnotationModal, closeCreateAnnotationModal, isOpenCreateAnnotationModal] = useModal();


    const clickCreateAnnotationOKButton = (event) => {
        event.preventDefault();
        closeCreateAnnotationModal();

        const postAnnotationUrl = `${APIServerConfiguration.host}/v1/slides/${annotations.slideId}/annotation`;
        Auth.currentSession().then(data => {
            const accessToken = data.getAccessToken().getJwtToken();
            return fetch(postAnnotationUrl, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${accessToken}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(annotation)
                }
            ).then(response => {
                appendRectangleAnnotation(viewer, annotation, annotations, setAnnotations);
            }).catch((error) => {
                console.error(error);
            });
        })

    }

    const clickCreateAnnotationCancelButton = (event) => {
        viewer.removeOverlay(annotation.annotationId);
        closeCreateAnnotationModal();
        setAnnotation({
            type: AnnotationType.Rectangle,
        })
    };

    const prepareRect = (annotationId) => {
        const annotationRect = document.createElement('div');
        annotationRect.id = annotationId;
        annotationRect.style.border = RectStyle.border;
        annotationRect.style.background = RectStyle.background;
        annotationRect.onclick = (event) => {console.log(event);}

        return annotationRect;
    }


    const initializeRect = (annotationId, event) => {
        const annotationRect = prepareRect(annotationId);
        const point = viewer.viewport.pointFromPixel(event.position);

        console.log(annotationId);

        // 矩形のサイズ計算

        // 横幅計算
        // const width = data.Image.Size.Width;
        const mppX = data.Image.MppX;
        console.log(`mppX: ${mppX}`);
        const pixelsForFixedRectangleWidth = FixedEdgeLengthMicron / mppX; // 必要なピクセル数
        // 縦幅計算
        // const height = data.Image.Size.Height;
        const mppY = data.Image.MppY;
        console.log(`mppY: ${mppY}`);
        const pixelsForFixedRectangleHeight = FixedEdgeLengthMicron / mppY; // 必要なピクセル数

        const pixelsForFixedRectangle = new OpenSeadragon.Point(pixelsForFixedRectangleWidth , pixelsForFixedRectangleHeight);
        const imageRectanglePoint = event.eventSource.viewport.pointFromPixel(event.eventSource.viewport.imageToViewerElementCoordinates(pixelsForFixedRectangle));

        const location = new OpenSeadragon.Rect(point.x, point.y, imageRectanglePoint.x, imageRectanglePoint.y);

        const textDiv = document.createElement('div');
        textDiv.style.color = `${TextAnnotationGreen}`;
        textDiv.style.position = "relative";
        textDiv.style.fontSize = "1.5em";
        textDiv.style.textShadow = AnnotationTextShadow;
        textDiv.style.top = "-2em";
        textDiv.style.whiteSpace = "nowrap";
        textDiv.style.wordBreak = "keep-all";
        textDiv.style.overflow = "visible";

        const text = document.createTextNode(`2.000 mm`);
        const sup = document.createElement("sup");
        const supValue = document.createTextNode("2");
        sup.appendChild(supValue);
        textDiv.appendChild(text);
        textDiv.appendChild(sup);

        annotationRect.appendChild(textDiv);

        setAnnotation({
            type: AnnotationType.Rectangle,
            annotationId: annotationId,
            startX: point.x,
            startY: point.y,
            endX: point.x + imageRectanglePoint.x,
            endY: point.y + imageRectanglePoint.y,
            width: FixedEdgeLength,
            height: FixedEdgeLength,
            unit: "mm",
        })

        viewer.addOverlay(annotationRect, location);
    }

    const clickEvent = (event) => {
        event.preventDefaultAction = true;
        const point = event.eventSource.viewport.pointFromPixel(event.position);
        const annotationId = crypto.randomUUID();
        initializeRect(annotationId, event);
        openCreateAnnotationModal();
    }

    useEffect(() => {
        if(viewer) {
            switch (controllerValue) {
                case AnnotationType.FixedSizeRectangle:
                    viewer.addOnceHandler(OpenSeadragonEvent.CanvasClick, (event) => {
                        clickEvent(event);
                        if (reset) {
                            reset("");
                        }
                    });
                    break;
            }
        }
    }, [controllerValue]);

    return (
        <>
            <CreateAnnotationModal>
                <View
                    ariaLabel="View example"
                    backgroundColor="var(--amplify-colors-white)"
                    borderRadius="6px"
                    border="1px solid var(--amplify-colors-black)"
                    boxShadow="3px 3px 5px 6px var(--amplify-colors-neutral-60)"
                    color="var(--amplify-colors-blue-60)"
                    maxWidth="100%"
                    padding="1rem"
                >
                    <form>
                        <TextAreaField
                            label="説明"
                            rows="3"
                            onChange={(e) => {
                                console.log(e.target.value)
                                setAnnotation({...annotation,
                                    annotationText: e.target.value
                                })
                            }}
                        />
                    </form>
                    <Button onClick={clickCreateAnnotationOKButton}>OK</Button>
                    <Button onClick={clickCreateAnnotationCancelButton}>キャンセル</Button>
                </View>
            </CreateAnnotationModal>
        </>
    );
}