import "./OpenSeadragonSvgOverlay"
import {OpenSeadragonAnnotationButtonGroup} from "./OpenSeadragonAnnotationButtonGroup.jsx";
import "./OpenSeadragonScaleBar";
import "./OpenSeadragonFiltering";

import React, {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import OpenSeadragon from "openseadragon";


export const OpenSeadragonViewer = ({ data, annotations, setAnnotations, width, height }) => {

    const [viewer, setViewer] = useState(null)

    const getWindowDimensions = () => {
        const width = window.innerWidth;
        const height = window.innerHeight - 200;
        // const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


    useEffect(()=> {
        if (data && viewer) {
            viewer.open(data);
        }
        const onResize = () => {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [data]);

    const InitOpenseadragon = () => {
        if(data) {
            Auth.currentSession().then((session) => {
                let token = session.getIdToken().getJwtToken()
                viewer && viewer.destroy();
                const openSeadragon = OpenSeadragon({
                    id: "openSeadragon",
                    animationTime: 0.5,
                    blendTime: 0.1,
                    constrainDuringPan: true,
                    maxZoomPixelRatio: 2,
                    minZoomLevel: 1,
                    visibilityRatio: 1,
                    zoomPerScroll: 1.2,
                    zoomPerClick: 1.2,
                    tileSources: data,
                    // 回転系
                    degrees: 0,
                    // ナビゲーター
                    showNavigator: true,
                    navigatorAutoFade: false,
                    navigatorPosition: "BOTTOM_LEFT",
                    // navigatorAutoResize: true,
                    loadTilesWithAjax: true,
                    ajaxHeaders: {
                        Authorization: `Bearer ${token}`
                    },
                    showRotationControl: true,
                    zoomInButton: "zoom-in",
                    zoomOutButton: "zoom-out",
                    homeButton: "home",
                    fullPageButton: "full-page",
                    rotateRightButton: "rotate-right",
                    rotateLeftButton: "rotate-left",
                    // ジェスチャーを使った設定
                    // gestureSettingsMouse: {}
                })

                openSeadragon.setFilterOptions({
                    filters: [{
                        processors: [
                            OpenSeadragon.Filters.GAMMA(1/1.8)
                        ]
                    }]
                });


                if (data && data['Image'] && data['Image']['MppX']) {
                    openSeadragon.scalebar({
                        type: OpenSeadragon.ScalebarType.MICROSCOPY,
                        // pixelsPerMeter: 3.356,
                        pixelsPerMeter: 10 ** 6 / data['Image']['MppX'],
                        minWidth: "150px",
                        location: OpenSeadragon.ScalebarLocation.TOP_RIGHT,
                        xOffset: 5,
                        yOffset: 10,
                        stayInsideImage: true,
                        color: "rgb(150, 150, 150)",
                        fontColor: "rgb(100, 100, 100)",
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        fontSize: "small",
                        barThickness: 2
                    });
                }
                setViewer(openSeadragon);
            });
        }
    }



    useEffect(() => {
        InitOpenseadragon();
        return () => {
            viewer && viewer.destroy();
        }
    }, [data])

    return (
        <>
            <OpenSeadragonAnnotationButtonGroup viewer={viewer} data={data} annotations={annotations} setAnnotations={setAnnotations}/>
            <div id="openSeadragon"
                 style={{
                     width: windowDimensions.width,
                     height: windowDimensions.height,
                 }}
            >
            </div>
        </>
    )
}
