import {OpenSeadragonLengthAnnotations} from "./annotations/OpenSeadragonLengthAnnotations";
import {OpenSeadragonRectangleAnnotations} from "./annotations/OpenSeadragonRectangleAnnotations.jsx";
import {OpenSeadragonArrowAnnotations} from "./annotations/OpenSeadragonArrowAnnotations";
import {Button, ToggleButton, ToggleButtonGroup} from "@aws-amplify/ui-react";
import {AnnotationType} from "./annotations/OpenSeadragonAnnotationType";
import {useEffect, useState} from "react";
import {MdOutlineRectangle} from "react-icons/md";
import {OpenSeadragonEvent} from "./annotations/OpenSeadragonEvent";
import {
    AiOutlineDelete, AiOutlineEye, AiOutlineEyeInvisible,
    AiOutlineFullscreen,
    AiOutlineHome, AiOutlineRotateLeft, AiOutlineRotateRight,
    AiOutlineSelect,
    AiOutlineZoomIn,
    AiOutlineZoomOut
} from "react-icons/ai";
import {OpenSeadragonRectangleAnnotationsController} from "./annotations/OpenSeadragonRectangleAnnotationsController";
import {OpenSeadragonFixedSizeRectangleAnnotation} from "./annotations/OpenSeadragonFixedSizeRectangleAnnotation";
import {Tooltip} from "react-tooltip";
import {BsArrowsMove, BsArrowUpLeft} from "react-icons/bs";
import {OpenSeadragonFilteringViewerModal} from "./OpenSeadragonFilteringViewerModal";
import {OpenSeadragonViewerSnapshot} from "./snapshot/OpenSeadragonViewerSnapshot";
import {BiRuler} from "react-icons/bi";

export const OpenSeadragonAnnotationButtonGroup = ({viewer, data, annotations, setAnnotations}) => {

    const [exclusiveValue, setExclusiveValue] = useState("");
    const [rotate, setRotate] = useState(0);
    const [isAnnotationVisible, setIsAnnotationVisible] = useState(true);

    if(viewer) {
        viewer.addHandler(OpenSeadragonEvent.Rotate, (event) => {
            setRotate(event.degrees);
        })
    }

    useEffect(() => {
        console.log("アノテーションの追加を検知しました!")
    }, [annotations])

    useEffect(() => {
        if(viewer) {
            if (exclusiveValue === "" || exclusiveValue === null) {
                viewer.removeAllHandlers(OpenSeadragonEvent.CanvasClick);
                viewer.removeAllHandlers(OpenSeadragonEvent.CanvasPress);
                viewer.removeAllHandlers(OpenSeadragonEvent.CanvasRelease);
                viewer.removeAllHandlers(OpenSeadragonEvent.CanvasDrag);
                viewer.removeAllHandlers(OpenSeadragonEvent.CanvasDragEnd);
                viewer.setMouseNavEnabled(true);
            }
        }
    }, [exclusiveValue])

    return (
        <>
            <Tooltip id="annotation" effect="solid" place="top" style={{zIndex: "1001"}}/>
            <OpenSeadragonLengthAnnotations
                viewer={viewer}
                data={data}
                annotations={annotations}
                setAnnotations={setAnnotations}
                rotate={rotate}
                controllerValue={exclusiveValue}
                isAnnotationVisible={isAnnotationVisible}
                reset={setExclusiveValue}
            />
            <OpenSeadragonArrowAnnotations
                viewer={viewer}
                data={data}
                annotations={annotations}
                setAnnotations={setAnnotations}
                rotate={rotate}
                isAnnotationVisible={isAnnotationVisible}
                controllerValue={exclusiveValue}
                reset={setExclusiveValue}
            />
            <OpenSeadragonRectangleAnnotationsController
                viewer={viewer}
                data={data}
                annotations={annotations}
                setAnnotations={setAnnotations}
                rotate={rotate}
                controllerValue={exclusiveValue}
                isAnnotationVisible={isAnnotationVisible}
                reset={setExclusiveValue}
            />
            <OpenSeadragonFixedSizeRectangleAnnotation
                viewer={viewer}
                data={data}
                annotations={annotations}
                setAnnotations={setAnnotations}
                rotate={rotate}
                controllerValue={exclusiveValue}
                reset={setExclusiveValue}
            />
            <ToggleButtonGroup isExclusive={true} value={exclusiveValue} onChange={(value) => {
                if (value) {
                    setExclusiveValue(value.toString())
                } else {
                    setExclusiveValue(null)
                }
            }}>
                <ToggleButton style={{height: "2em"}} value={AnnotationType.FixedSizeRectangle} data-tooltip-id="annotation" data-tooltip-content="固定サイズ矩形アノテーション作成">
                    <MdOutlineRectangle/>
                    <div style={{fontSize: "0.3em"}}>2mm<sup>2</sup></div>
                </ToggleButton>
                <ToggleButton style={{height: "2em"}} value={AnnotationType.Rectangle} data-tooltip-id="annotation" data-tooltip-content="矩形アノテーション作成">
                    <MdOutlineRectangle/>
                </ToggleButton>
                <ToggleButton style={{height: "2em"}} value={AnnotationType.Length} data-tooltip-id="annotation" data-tooltip-content="長さ測定アノテーション">
                    <BiRuler/>
                </ToggleButton>
                <ToggleButton style={{height: "2em"}} value={AnnotationType.Arrow} data-tooltip-id="annotation" data-tooltip-content="矢印アノテーション">
                    <BsArrowUpLeft/>
                </ToggleButton>
                <ToggleButton style={{height: "2em"}} value={AnnotationType.Select} data-tooltip-id="annotation" data-tooltip-content="アノテーション選択">
                    <AiOutlineSelect/>
                </ToggleButton>
                <ToggleButton style={{height: "2em"}} value={AnnotationType.Move} data-tooltip-id="annotation" data-tooltip-content="アノテーション移動">
                    <BsArrowsMove/>
                </ToggleButton>
                <ToggleButton style={{height: "2em"}} value={AnnotationType.Delete} data-tooltip-id="annotation" data-tooltip-content="アノテーション削除">
                    <AiOutlineDelete/>
                </ToggleButton>
                <OpenSeadragonViewerSnapshot viewer={viewer}/>
                <Button style={{height: "2em"}} id="zoom-in" data-tooltip-id="annotation" data-tooltip-content="拡大">
                    <AiOutlineZoomIn/>
                </Button>
                <Button style={{height: "2em"}} id="zoom-out" data-tooltip-id="annotation" data-tooltip-content="縮小">
                    <AiOutlineZoomOut/>
                </Button>
                <Button style={{height: "2em"}} id="home" data-tooltip-id="annotation" data-tooltip-content="拡大をデフォルトに戻す">
                    <AiOutlineHome/>
                </Button>
                <Button style={{height: "2em"}} id="full-page" data-tooltip-id="annotation" data-tooltip-content="全画面表示">
                    <AiOutlineFullscreen/>
                </Button>
                <Button style={{height: "2em"}} id="rotate-left" data-tooltip-id="annotation" data-tooltip-content="反時計回り(90°)">
                    <AiOutlineRotateLeft />
                </Button>
                <Button style={{height: "2em"}} id="rotate-right" data-tooltip-id="annotation" data-tooltip-content="時計回り(90°)">
                    <AiOutlineRotateRight/>
                </Button>
                <Button style={{height: "2em"}} onClick={(event) => { setIsAnnotationVisible(!isAnnotationVisible); }} data-tooltip-id="annotation" data-tooltip-content="アノテーション可視性切り替え">
                    {!isAnnotationVisible && <AiOutlineEye/>}
                    {isAnnotationVisible && <AiOutlineEyeInvisible/>}
                </Button>
                <OpenSeadragonFilteringViewerModal viewer={viewer}/>
            </ToggleButtonGroup>
            { /* <div>{exclusiveValue}</div> */ }
        </>
    )
}