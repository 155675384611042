import {Button, Card, Divider, Grid, Heading, useTheme} from "@aws-amplify/ui-react";
import {Auth} from "aws-amplify";

export const PageHeader = ({userName}) => {
    const { tokens } = useTheme();
    return (
        <>
            {/*必要になったらここのコメントアウトを外す*/}
            <div hidden>
            <Heading level={1} backgroundColor={tokens.colors.blue[20]}>BonBon Viewer</Heading>
            <Grid
                columnGap="0.5rem"
                rowGap="0.5rem"
                templateColumns="1fr 8fr 1fr"
                templateRows="1fr"
                backgroundColor={tokens.colors.blue[15]}
            >
                <Card columnStart="1" columnEnd="1">
                    <Heading level={6}>ユーザー名</Heading>
                    {userName}
                </Card>
                <Card columnStart="3" columnEnd="-1">
                    <Button onClick={() => Auth.signOut()}>サインアウト</Button>
                </Card>
            </Grid>
            </div>
        </>
    )
}