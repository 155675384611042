export const amplifyConfig = {
    Auth: {
        region: 'ap-northeast-1',
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
            scope: [
                process.env.REACT_APP_COGNITO_OAUTH_SCOPE
            ],
            redirectSignIn: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_IN,
            redirectSignOut: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_OUT,
            responseType: process.env.REACT_APP_COGNITO_RESPONSE_TYPE
        }
    }
}

