import {OpenSeadragonRectangleAnnotation} from "./OpenSeadragonRectangleAnnotation.jsx";

export const OpenSeadragonRectangleAnnotations = ({ viewer, data, annotations, setAnnotations, rotate, controllerValue, isAnnotationVisible, reset }) => {

    return annotations ? (
        <>
            {
                annotations.annotations.filter((annot) => annot['type'] === 'rectangle').map((annot) =>
                    <OpenSeadragonRectangleAnnotation key={annot.annotationId} annotationId={annot.annotationId} viewer={viewer}
                                                      annotation={annot} annotations={annotations} setAnnotations={setAnnotations} rotate={rotate} isAnnotationVisible={isAnnotationVisible} controllerValue={controllerValue} setControllerValue={reset}/>
                )
            }
        </>
    ) : (<></>)
}