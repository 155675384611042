import OpenSeadragon from 'openseadragon';
import {useEffect, useState} from "react";
import {RectStyle} from "./OpenSeadragonAnnotationConstants";
import {AnnotationType} from "./OpenSeadragonAnnotationType";
import {useOpenSeadragonDeleteRectangleAnnotationModal} from "./rectangle/OpenSeadragonDeleteRectangleAnnotationModal";
import {useOpenSeadragonUpdateRectangleAnnotationModal} from "./rectangle/OpenSeadragonUpdateRectangleAnnotationModal";
import {APIServerConfiguration} from "../../../../config/APIServerConfiguration";
import {Auth} from "aws-amplify";


const textStyle = {
    color: "#17B169",
    position: "relative",
    fontSize: "1.5em",
    textShadow: `1px 1px 0 #FFF, -1px -1px 0 #FFF,
              -1px 1px 0 #FFF, 1px -1px 0 #FFF,
              0px 1px 0 #FFF,  0-1px 0 #FFF,
              -1px 0 0 #FFF, 1px 0 0 #FFF`,
    top: "-2em",
    whiteSpace: "nowrap",
    wordBreak: "keep-all",
    overflow: "visible",
}

const AnnotationText = ({annotationId, annotationRect, width, height, unit, rotate}) => {

    const annotationText = document.createElement('div');
    annotationText.id = `${annotationId}/text`;
    annotationText.style.color = textStyle.color;
    annotationText.style.position = textStyle.position;
    annotationText.style.fontSize = textStyle.fontSize;
    annotationText.style.textShadow = textStyle.textShadow;
    annotationText.style.top = textStyle.top;
    annotationText.style.whiteSpace = textStyle.whiteSpace;
    annotationText.style.wordBreak = textStyle.wordBreak;
    annotationText.style.overflow = textStyle.overflow;
    annotationText.style.cursor = "default";

    const annotationTextContent = document.createTextNode(`${(width * height).toPrecision(4)} ${unit}`);
    const sup = document.createElement("sup");
    const supValue = document.createTextNode("2");
    sup.appendChild(supValue);
    annotationText.appendChild(annotationTextContent);
    annotationText.appendChild(sup);
    annotationRect.appendChild(annotationText);
    sup.appendChild(supValue);
    annotationText.appendChild(annotationTextContent);
    annotationText.appendChild(sup);
    annotationRect.appendChild(annotationText);


    useEffect(() => {

        const annotation = document.getElementById(`${annotationId}/text`);
        if (annotation) {
            annotation.style.transform = `rotate(-${rotate % 360}deg)`;
            switch (rotate % 360) {
                case 0:
                    annotation.style.transformOrigin = "top left";
                    annotation.style.top = "-2em";
                    annotation.style.left = null;
                    break;
                case 90:
                    annotation.style.transformOrigin = "top left";
                    annotation.style.left = "-2em";
                    annotation.style.top = annotation.parentNode.style.height;
                    break;
                case 180:
                   annotation.style.transformOrigin = "center left";
                   annotation.style.left = `${annotation.parentNode.style.width}`;
                   annotation.style.top = `${annotation.parentNode.style.height}`;
                   break;
                case 270:
                    annotation.style.transformOrigin = "bottom left";
                    annotation.style.top = "-2em";
                    annotation.style.left = annotation.parentNode.style.width;
                    break;
            }
        }

    }, [rotate]);

    return <></>
}

export const OpenSeadragonRectangleAnnotation = ({ annotationId, viewer, annotation, annotations, setAnnotations, rotate, controllerValue, isAnnotationVisible, setControllerValue}) => {

    const [DeleteRectangleAnnotationModal, openDeleteAnnotationModal, closeDeleteAnnotationModal] = useOpenSeadragonDeleteRectangleAnnotationModal(
        {annotation, annotations, setAnnotations, setControllerValue, viewer}
    )

    const [UpdateRectangleAnnotationModal, openUpdateAnnotationModal, closeUpdateAnnotationModal] = useOpenSeadragonUpdateRectangleAnnotationModal(
        {annotation, annotations, setAnnotations, setControllerValue, viewer}
    );


    const annotationLocation = new OpenSeadragon.Rect(
        annotation.startX < annotation.endX ? annotation.startX : annotation.endX,
        annotation.startY < annotation.endY ? annotation.startY : annotation.endY,
        Math.abs(annotation.endX - annotation.startX),
        Math.abs(annotation.endY - annotation.startY)
    );

    const annotationRect = document.createElement('div');
    annotationRect.id = annotation.annotationId;
    annotationRect.style.border = RectStyle.border;
    annotationRect.style.background = RectStyle.background;
    annotationRect.style.zIndex = RectStyle.zIndex;

    if(viewer) {
        if(!viewer.getOverlayById(annotationId)) {
            viewer.addOverlay(annotationRect, annotationLocation);
        }
    }


    let selected = null;
    let mousePosition = {x: 0, y: 0};

    const [selectedAnnotationId, setSelectedAnnotationId] = useState(annotationId);

    useEffect(() => {
        console.log("アノテーションの可視性切り替えが行われました");
        const a = document.getElementById(annotation.annotationId);
        if (a) {
            a.style.visibility = isAnnotationVisible ? "visible" : "hidden";
        }
    }, [isAnnotationVisible]);

    useEffect(()=>{
        const annotation = document.getElementById(annotationId);
        if(annotation) {
            console.log("マウス操作の初期化");
            annotation.onclick = null;
            annotation.onmousedown = null;
            annotation.onmousemove = null;
            annotation.parentNode.parentNode.onmousemove = null;
            annotation.parentNode.parentNode.onmouseup = null;
            // annotation.draggable = false;
            switch(controllerValue) {
                case AnnotationType.Delete:
                    console.log("削除処理が選択されたので、onclick属性を追加します");
                    annotation.style.cursor = "pointer";
                    annotation.onclick = (event) => {
                        openDeleteAnnotationModal();
                    }
                    break;
                case AnnotationType.Select:
                    annotation.style.cursor = "pointer";
                    console.log("選択処理が選択されたので、onclick属性を追加します");
                    annotation.onclick = (event) => {
                        openUpdateAnnotationModal();
                    }
                    break;
                case AnnotationType.Move:
                    console.log("移動処理が選択されたので、onmousedown、onmouseup、onmousemove属性を追加します");
                    annotation.style.cursor = "grab";
                    annotation.onclick = null;
                    annotation.onmousedown = (event) => {
                        event.preventDefault();
                        console.log("mousedown");
                        console.log(`offsetX: ${event.clientX}`);
                        console.log(`offsetY: ${event.clientY}`);
                        console.log(event.target.id);
                        console.log(selectedAnnotationId);
                        mousePosition.x = event.clientX;
                        mousePosition.y = event.clientY - 50;
                        selected = true;

                        annotation.parentNode.parentNode.onmousemove = (event) => {
                            event.preventDefault();
                            if(viewer && selected) {
                                console.log("mousemove");
                                console.log(`offsetX: ${event.clientX}`);
                                console.log(`offsetY: ${event.clientY}`);

                                mousePosition.x = event.clientX;
                                mousePosition.y = event.clientY - 50;

                                console.log(event.target.id);
                                console.log(event.currentTarget.id);
                                const overlay = viewer.getOverlayById(selectedAnnotationId);
                                const point = viewer.viewport.pointFromPixel(new OpenSeadragon.Point(mousePosition.x, mousePosition.y));
                                viewer.updateOverlay(overlay.element, new OpenSeadragon.Rect(point.x, point.y, overlay.width, overlay.height));
                            }
                        }

                        annotation.parentNode.parentNode.onmouseup = (event) => {
                            event.preventDefault();
                            console.log("onmouseup");
                            console.log(`offsetX: ${event.clientX}`);
                            console.log(`offsetY: ${event.clientY}`);
                            console.log(event.target.id);
                            console.log(selectedAnnotationId);
                            const overlay = viewer.getOverlayById(selectedAnnotationId);
                            const point = viewer.viewport.pointFromPixel(new OpenSeadragon.Point(mousePosition.x, mousePosition.y));
                            viewer.updateOverlay(overlay.element, new OpenSeadragon.Rect(point.x, point.y, overlay.width, overlay.height));
                            selected = null;
                            setControllerValue("");

                            event.target.onmouseup = null;
                            event.target.onmousedown = null;
                            event.target.onmousemove = null;

                            const newAnnotations = annotations.annotations.map((annotation) => {
                                if(annotation.annotationId === selectedAnnotationId) {
                                    return {...annotation,
                                        startX: point.x,
                                        startY: point.y,
                                        endX: point.x + overlay.width,
                                        endY: point.y + overlay.height,
                                    };
                                }
                                return annotation;
                            })

                            console.log(newAnnotations);
                            setAnnotations({
                                slideId: annotations.slideId,
                                annotations: newAnnotations,
                            })

                            // サーバーサイドのアノテーションを更新する
                            const baseUrl = `${APIServerConfiguration.host}/v1/slides${window.location.pathname}`;
                            const specificAnnotationUrl = `${baseUrl}/annotation/${selectedAnnotationId}`;
                            Auth.currentSession().then(data => {
                                const accessToken = data.getAccessToken().getJwtToken();
                                return fetch(specificAnnotationUrl, {
                                    method: "GET",
                                    headers: {
                                        "Authorization": `Bearer ${accessToken}`,
                                    }
                                }).then(response => response.json())
                                    .then(data => {
                                        let currentAnnotation = data;
                                        currentAnnotation.startX = point.x;
                                        currentAnnotation.startY = point.y;
                                        currentAnnotation.endX = point.x + overlay.width;
                                        currentAnnotation.endY = point.y + overlay.height;
                                        return fetch(specificAnnotationUrl, {
                                            method: 'PUT',
                                            headers: {
                                                "Authorization": `Bearer ${accessToken}`,
                                                "Content-Type": "application/json"
                                            },
                                            body: JSON.stringify(currentAnnotation)
                                        }).then(response => response.json()).catch(console.error);
                                    })
                            })
                        }
                    }
                    break;
                default:
                    annotation.style.cursor = "default";
            }
        }
    }, [controllerValue])


    return (
        <>
            <AnnotationText annotationId={annotationId} annotationRect={annotationRect} width={annotation.width} height={annotation.height} unit={annotation.unit} rotate={rotate}/>
            <DeleteRectangleAnnotationModal/>
            <UpdateRectangleAnnotationModal/>
        </>
    );
}