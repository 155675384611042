import {useModal} from "react-hooks-use-modal";
import {useEffect, useState} from "react";
import OpenSeadragon from "openseadragon";
import {Tooltip} from "react-tooltip";
import {Button, TextField, View} from "@aws-amplify/ui-react";
import {AiOutlineSetting} from "react-icons/ai";

export const OpenSeadragonFilteringViewerModal = ({viewer}) => {

    const [SettingWindow, openSettingWindow, closeSettingWindow, iisSettingWindowOpen] = useModal();
    const [gamma, setGamma] = useState(1.8);

    useEffect(() => {
        if(viewer) {
            viewer.setFilterOptions({
                filters: [{
                    processors: [
                        OpenSeadragon.Filters.GAMMA(1/gamma)
                    ]
                }]
            })
        }
    }, [gamma]);

    return (
        <>
            <Tooltip id="filter-setting-tooltip" effect="solid" place="top" style={{zIndex: "1001"}}/>
            <Button style={{height: "2em"}} data-tooltip-id="filter-setting-tooltip" data-tooltip-content="表示設定(ガンマ値調整など)" onClick={openSettingWindow}>
                <AiOutlineSetting/>
            </Button>
            <SettingWindow>
                <View
                    ariaLabel="View example"
                    backgroundColor="var(--amplify-colors-white)"
                    borderRadius="6px"
                    border="1px solid var(--amplify-colors-black)"
                    boxShadow="3px 3px 5px 6px var(--amplify-colors-neutral-60)"
                    color="var(--amplify-colors-blue-60)"
                    maxWidth="100%"
                    padding="1rem"
                >
                    <div>フィルタリング設定ウィンドウ</div>
                    <TextField label="ガンマ値" type="number" step="0.1" min="0.0" value={gamma} onChange={(event) => {
                        let value;
                        if (parseFloat(event.target.value) < 0.1) {
                            value = 0.1;
                        } else if (parseFloat(event.target.value) > 10) {
                            value = 10;
                        } else {
                            value = parseFloat(event.target.value);
                        }
                        setGamma(value);
                    }}/>
                    <Button onClick={closeSettingWindow}>閉じる</Button>
                </View>
            </SettingWindow>
        </>
    )
}