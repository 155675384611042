import {AnnotationType} from "./OpenSeadragonAnnotationType";
import {ArrowAnnotationMarkerStart, OpenSeadragonArrowAnnotation} from "./OpenSeadragonArrowAnnotation";
import {useEffect} from "react";
import {OpenSeadragonEvent} from "./OpenSeadragonEvent";
import * as d3 from "d3";
import {DefaultStrokeWidth, TextAnnotationGreen} from "./OpenSeadragonAnnotationConstants";
import {useOpenSeadragonCreateArrowAnnotationModal} from "./arrow/OpenSeadragonCreateArrowAnnotationModal";
import {clearHandlers} from "./util/OpenSeadragonUtil";


const drawArrowAnnotation = (viewer, data, startPoint, endPoint, annotations, setAnnotations, annotationId) => {
    const overlay = viewer.svgOverlay();
    const g = overlay.node();
    if(!document.getElementById(ArrowAnnotationMarkerStart)) {
        const markerStartArrow = d3.select((g)).append("marker")
            .attr("id", ArrowAnnotationMarkerStart)
            .attr("viewBox", "0 0 20 20")
            .attr("refX", "10")
            .attr("refY", "10")
            .attr("markerUnits", "strokeWidth")
            .attr("markerWidth", "6")
            .attr("markerHeight", "6")
            .attr("orient", "auto")
        const markerStartArrowPath = markerStartArrow.append("polygon")
            .attr("points", "20,0 20,20 0,10")
            .attr("fill", TextAnnotationGreen)
    }

    const sp = viewer.viewport.pointFromPixel(startPoint);
    const ep = viewer.viewport.pointFromPixel(endPoint);

    const a = document.getElementById(annotationId);
    if(a) {
        a.remove();
    }

    if(viewer) {
        const arrow = d3.select(g).append("line")
            .style("stroke", TextAnnotationGreen)
            .style("stroke-width", DefaultStrokeWidth / viewer.viewport.getZoom(true))
            .attr("id", annotationId)
            .attr("x1", sp.x)
            .attr("x2", ep.x)
            .attr("y1", sp.y)
            .attr("y2", ep.y)
            .attr("marker-start", `url(#${ArrowAnnotationMarkerStart})`)
    }
}

export const OpenSeadragonArrowAnnotations = ({viewer, data, annotations, setAnnotations, rotate, isAnnotationVisible, controllerValue, reset}) => {

    let createStartPoint = {x: 0, y:0}
    let createEndPoint = {x: 0, y:0}
    let annotationId = "";

    const [CreateArrowAnnotationModal,
        openCreateArrowAnnotationModal,
        closeCreateArrowAnnotationModal] = useOpenSeadragonCreateArrowAnnotationModal({
        viewer, createStartPoint, createEndPoint, annotations, setAnnotations, reset});

    const pressStartCreateEvent = (event) => {
        event.preventDefaultAction = true;
        createStartPoint = event.position;
        createEndPoint = event.position;
        annotationId = crypto.randomUUID();
        drawArrowAnnotation(viewer, data, createStartPoint, createEndPoint, annotations, setAnnotations, annotationId);
    }

    const draggingCreateEvent = (event) => {
        event.preventDefaultAction = true;
        createEndPoint = event.position;
        console.log(createEndPoint);
        drawArrowAnnotation(viewer, data, createStartPoint, createEndPoint, annotations, setAnnotations, annotationId);
    }

    const dragEndCreateEvent = (event) => {
        event.preventDefaultAction = true;
        createEndPoint = event.position;
        drawArrowAnnotation(viewer, data, createStartPoint, createEndPoint, annotations, setAnnotations, annotationId);
    }

    const releaseCreateEvent = (event) => {
        event.preventDefaultAction = true;
        createEndPoint = event.position;
        drawArrowAnnotation(viewer, data, createStartPoint, createEndPoint, annotations, setAnnotations, annotationId);
        console.log(createStartPoint);
        openCreateArrowAnnotationModal(annotationId);
        reset("");
    }

    useEffect(() => {
       const svgOverlay = document.getElementById("svg-overlay");
       if(svgOverlay) {
           svgOverlay.style.visibility = isAnnotationVisible ? "visible" : "hidden";
       }
    }, [isAnnotationVisible]);

    useEffect(() => {
        switch(controllerValue) {
            case AnnotationType.Arrow:
                console.log("矢印アノテーションの作成");
                clearHandlers(viewer);
                viewer.addOnceHandler(OpenSeadragonEvent.CanvasClick, (event) => {event.preventDefaultAction = true;});
                viewer.addOnceHandler(OpenSeadragonEvent.CanvasPress, pressStartCreateEvent);
                viewer.addHandler(OpenSeadragonEvent.CanvasRelease, releaseCreateEvent);
                viewer.addHandler(OpenSeadragonEvent.CanvasDrag, draggingCreateEvent);
                viewer.addOnceHandler(OpenSeadragonEvent.CanvasDragEnd, dragEndCreateEvent);
                break;
        }
    }, [controllerValue])

    return viewer && annotations ? (<>
        <CreateArrowAnnotationModal/>
        {
            annotations.annotations.filter((annotation) => annotation['type'] === AnnotationType.Arrow)
                .map((annotation) =>
                    <OpenSeadragonArrowAnnotation
                        key={annotation.annotationId}
                        annotationId={annotation.annotationId}
                        viewer={viewer}
                        data={data}
                        annotation={annotation}
                        annotations={annotations}
                        setAnnotations={setAnnotations}
                        rotate={rotate}
                        isAnnotationVisible={isAnnotationVisible}
                        controllerValue={controllerValue}
                        setControllerValue={reset}
                    />
                )
        }
    </>) : (<></>)
}