import {useEffect} from "react";
import * as d3 from "d3";
import {
    AnnotationTextShadow,
    DefaultStrokeWidth,
    DefaultTextSize,
    TextAnnotationGreen, TextStyle
} from "./OpenSeadragonAnnotationConstants";
import OpenSeadragon from "openseadragon";
import {AnnotationType} from "./OpenSeadragonAnnotationType";
import {useOpenSeadragonDeleteArrowAnnotationModal} from "./arrow/OpenSeadragonDeleteArrowAnnotationModal";
import {useOpenSeadragonUpdateArrowAnnotationModal} from "./arrow/OpenSeadragonUpdateArrowAnnotationModal";
import {getRectangleLocation} from "./OpenSeadragonLengthAnnotation";
import {APIServerConfiguration} from "../../../../config/APIServerConfiguration";
import {Auth} from "aws-amplify";


export const ArrowAnnotationMarkerStart= "ArrowAnnotationMarker"

const saveAnnotation = (annotation, annotations, setAnnotations) => {
    const baseUrl = `${APIServerConfiguration.host}/v1/slides${window.location.pathname}`;
    const specificAnnotationUrl = `${baseUrl}/annotation/${annotation.annotationId}`;

    Auth.currentSession().then(data => {
        const accessToken = data.getAccessToken().getJwtToken();
        return fetch(specificAnnotationUrl,
            {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(annotation)
            })
            .then(response => response.json())
            .catch(error => console.log(error))
    })
}

const addAnnotation = (viewer, annotation) => {
    const overlay = viewer.svgOverlay();
    const g = overlay.node();
    if(!document.getElementById(ArrowAnnotationMarkerStart)) {
        const markerStartArrow = d3.select((g)).append("marker")
            .attr("id", ArrowAnnotationMarkerStart)
            .attr("viewBox", "0 0 20 20")
            .attr("refX", "10")
            .attr("refY", "10")
            .attr("markerUnits", "strokeWidth")
            .attr("markerWidth", "6")
            .attr("markerHeight", "6")
            .attr("orient", "auto")
        const markerStartArrowPath = markerStartArrow.append("polygon")
            .attr("points", "20,0 20,20 0,10")
            .attr("fill", TextAnnotationGreen)
    }
    const arrow = d3.select(g).append("line")
        .style("stroke", TextAnnotationGreen)
        .style("stroke-width", DefaultStrokeWidth / viewer.viewport.getZoom(true))
        .attr("id", annotation['annotationId'])
        .attr("x1", annotation['startX'])
        .attr("x2", annotation['endX'])
        .attr("y1", annotation['startY'])
        .attr("y2", annotation['endY'])
        .attr("marker-start", `url(#${ArrowAnnotationMarkerStart})`)
}

export const addTextNode= (viewer, annotation) => {
    if(document.getElementById(annotation.annotationId)) {
        const checkIfTextNodeExists = document.getElementById(`${annotation['annotationId']}/text`);
        if (checkIfTextNodeExists) {
            return;
        }

        const textDiv = document.createElement("div");
        textDiv.id = `${annotation['annotationId']}/text`;
        textDiv.style.color = TextStyle.color;
        textDiv.style.position = TextStyle.position;
        textDiv.style.fontSize = TextStyle.fontSize;
        textDiv.style.textShadow = TextStyle.textShadow;
        textDiv.style.top = "0px";
        textDiv.style.whiteSpace = TextStyle.whiteSpace;
        textDiv.style.wordBreak = TextStyle.wordBreak;
        textDiv.style.overflow = TextStyle.overflow;
        // textDiv.style.transformOrigin = "top left";
        textDiv.style.zIndex = "1000";

        // const textHeightOffset = viewer.viewport.pointFromPixel(new OpenSeadragon.Point(0, 16 * 2))
        const offset = 16 / window.innerHeight / viewer.viewport.getZoom();
        // console.log(textHeightOffset.y);
        // console.log((annotation['startY'] + annotation['endY']) / 2)

        const textDivLocation = new OpenSeadragon.Rect(
            (annotation['startX'] + annotation['endX']) / 2,
            (annotation['startY'] + annotation['endY']) / 2 - offset,
            0,
            0
        );

        const textNode = document.createTextNode(annotation['annotationText'] ? annotation['annotationText'] : "");
        textDiv.appendChild(textNode);

        if (viewer) {
            viewer.addOverlay(textDiv, textDivLocation);
            // 回転しないように強制
            viewer.getOverlayById(textDiv).rotationMode = OpenSeadragon.OverlayRotationMode.NO_ROTATION;
        }
    }
}

const updateAnnotation = (viewer, annotation) => {
    const line = document.getElementById(annotation['annotationId']);
    const zoom = viewer.viewport.getZoom(false);
    if(viewer && line && zoom >= 1) {
        line.style.strokeWidth = `${DefaultStrokeWidth/zoom}`;
    }

    const text = document.getElementById(`${annotation['annotationId']}/text`);
    const offset = 16 / window.innerHeight / viewer.viewport.getZoom();
    if (text) {
        const textLocation = new OpenSeadragon.Rect(
            (annotation['startX'] + annotation['endX']) / 2,
            (annotation['startY'] + annotation['endY']) / 2 - offset,
            0,
            0
        );
        viewer.updateOverlay(text, textLocation);
    } else {
        addTextNode(viewer, annotation);
    }
}

export const OpenSeadragonArrowAnnotation = ({annotationId, viewer, data, annotation, annotations, setAnnotations, rotate, isAnnotationVisible, controllerValue, setControllerValue}) => {

    const [DeleteArrowAnnotationModal,
        openDeleteArrowAnnotationModal,
        closeDeleteArrowAnnotationModal] = useOpenSeadragonDeleteArrowAnnotationModal({
        annotation, annotations, setAnnotations, setControllerValue, viewer
        });

    const [UpdateArrowAnnotationModal,
        openUpdateArrowAnnotationModal,
        closeUpdateArrowAnnotationModal] = useOpenSeadragonUpdateArrowAnnotationModal({
        annotation, annotations, setAnnotations, setControllerValue, viewer
        });

    const updateZoom = (event) => {
        updateAnnotation(viewer, annotation);
    }

    useEffect(() => {
        if(viewer) {
            viewer.addHandler('zoom', updateZoom);
        }
        return () => {
            viewer.removeHandler('zoom', updateZoom)
        };
    }, [])

    useEffect(() => {
        if(viewer && !document.getElementById(annotation.annotationId)) {
            addAnnotation(viewer, annotation);
            addTextNode(viewer, annotation);
        }
    }, [annotations]);

    const clearEventListeners = (id) => {
        const element = document.getElementById(id);
        if(element) {
            element.onclick = null;
            element.onmousedown = null;
        }
        const elementText = document.getElementById(`${id}/text`);
        if(elementText) {
            elementText.onclick = null;
            elementText.onmousedown = null;
            elementText.parentNode.parentNode.onmousemove = null;
            elementText.parentNode.parentNode.onmouseup = null;
        }
    }


    // マウスのポジション
    let mousePosition = {x: 0, y: 0};
    let selectedAnnotationId = null;
    let lineDelta = {x: 0, y:0}
    const startMoveElement = (event) => {
        event.preventDefault();
        selectedAnnotationId = event.target.id.replace("/text", "");;
        console.log(selectedAnnotationId);

        mousePosition = {
            x: event.clientX,
            y: event.clientY - 50
        }
        lineDelta = {
            x: annotation.endX - annotation.startX,
            y: annotation.endY - annotation.startY
        }

        const line = document.getElementById(selectedAnnotationId);
        const text = document.getElementById(`${selectedAnnotationId}/text`);
        if(text) {
            text.parentNode.parentNode.onmousemove = (event) => {
                mousePosition = {
                    x: event.clientX,
                    y: event.clientY - 50
                }
                const mousePoint = viewer.viewport.pointFromPixel(new OpenSeadragon.Point(mousePosition.x, mousePosition.y));

                // 要素をいじる
                line.setAttribute('x1', `${mousePoint.x}`);
                line.setAttribute('y1', `${mousePoint.y}`);
                line.setAttribute('x2', `${mousePoint.x + lineDelta.x}`);
                line.setAttribute('y2', `${mousePoint.y + lineDelta.y}`);


                annotation.startX = mousePoint.x;
                annotation.startY = mousePoint.y;
                annotation.endX = mousePoint.x + lineDelta.x;
                annotation.endY = mousePoint.y + lineDelta.y;

                const location = getRectangleLocation(annotation);
                viewer.updateOverlay(text, location);
            }
            text.parentNode.parentNode.onmouseup = (event) => {
                mousePosition = {
                    x: event.clientX,
                    y: event.clientY - 50
                }
                console.log(mousePosition);
                const mousePoint = viewer.viewport.pointFromPixel(new OpenSeadragon.Point(mousePosition.x, mousePosition.y));
                console.log(mousePoint);

                // 直接要素をいじる
                line.setAttribute('x1', `${mousePoint.x}`);
                line.setAttribute('y1', `${mousePoint.y}`);
                line.setAttribute('x2', `${mousePoint.x + lineDelta.x}`);
                line.setAttribute('y2', `${mousePoint.y + lineDelta.y}`);


                annotation.startX = mousePoint.x;
                annotation.startY = mousePoint.y;
                annotation.endX = mousePoint.x + lineDelta.x;
                annotation.endY = mousePoint.y + lineDelta.y;

                const rectangleLocation = getRectangleLocation(annotation);
                viewer.updateOverlay(text, rectangleLocation);

                saveAnnotation(annotation, annotations, setAnnotations);

                text.parentNode.parentNode.onmousemove = null;
                text.parentNode.parentNode.onmouseup = null;
                setControllerValue("");
            }
        }
    }

    useEffect(() => {
        const at = document.getElementById(annotationId);
        const tt = document.getElementById(`${annotationId}/text`);
        switch (controllerValue) {
            case AnnotationType.Delete:
                console.log("矢印アノテーションでDeleteを設定しました");
                at.style.cursor = "pointer";
                at.onclick = (event) => {
                    openDeleteArrowAnnotationModal();
                }
                tt.style.cursor = "pointer";
                tt.onclick = (event) => {
                    openDeleteArrowAnnotationModal();
                }
                break;
            case AnnotationType.Select:
                console.log("矢印アノテーションでSelectを設定しました");
                at.style.cursor = "pointer";
                at.onclick = (event) => {
                    openUpdateArrowAnnotationModal();
                }
                tt.style.cursor = "pointer";
                tt.onclick = (event) => {
                    openUpdateArrowAnnotationModal();
                }
                break;
            case AnnotationType.Move:
                console.log("矢印アノテーションでMoveを設定しました")
                at.style.cursor = "grab";
                at.onmousedown = startMoveElement;
                tt.style.cursor = "grab";
                tt.onmousedown = startMoveElement;
                break;
            default:
                at.style.cursor = "default";
                tt.style.cursor = "default";
                clearEventListeners(annotationId);
        }
    }, [controllerValue])

    useEffect(() => {
        const t = document.getElementById(`${annotationId}/text`);
        if(t) {
            t.style.visibility = isAnnotationVisible ? "visible" : "hidden";
        }

    }, [isAnnotationVisible]);

    return (
        <>
            <DeleteArrowAnnotationModal/>
            <UpdateArrowAnnotationModal/>
        </>
    )
}