import {Button, View} from "@aws-amplify/ui-react";
import {Tooltip} from "react-tooltip";
import {AiFillCamera} from "react-icons/ai";
import {useModal} from "react-hooks-use-modal";
import domtoimage from "dom-to-image";
import {WebPathConfiguration} from "../../../../config/WebPathConfiguration";
import {Auth} from "aws-amplify";

export const OpenSeadragonViewerSnapshot = ({viewer}) => {

    const [SnapshotSuccessModal, openSnapshotModal, closeSnapshotModal] = useModal();
    const [SnapshotFailureModal, openSnapshotFailureModal, closeSnapshotFailureModal] = useModal();

    const ClickSnapshotButton = () => {
        // https://github.com/tsayen/dom-to-image でスクリーンショットを取得する
        // aタグを使って画像をダウンロードする。
        // TODO: 画像のアップロード先を指定してアップロードする。

        const openSeaDragonViewerElement = document.getElementById("openSeadragon");

        viewer.navigator.element.style.display = "none";

        const uploadSnapshotURL = `${WebPathConfiguration.host}/webpath/api/external/hyohonFile/addMicro`;
        domtoimage.toJpeg(openSeaDragonViewerElement, {quality: 0.95})
            .then((dataUrl) => {
                const body = new FormData();
                // body.append('file', atob(dataUrl), "sample.jpg");

                return fetch(dataUrl).then(response => response.blob()).then(fileBlob => {
                    body.append('file', fileBlob, "snapshot.jpg");

                    // wsiIdを取得する
                    console.log(`pathname: ${window.location.pathname}`)
                    const wsiId = window.location.pathname.split('/')[1];
                    console.log(`wsiId: ${wsiId}`);
                    body.append('wsiId', wsiId);

                    Auth.currentSession().then(data => {
                        viewer.navigator.element.style.display = "inline-block";
                        const accessToken = data.getAccessToken().getJwtToken();
                        let requestInfo = {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                // fetchでmultipart/form-dataを送信する場合は、Content-Typeを指定しないこと
                                // 'Content-Type': 'multipart/form-data'
                            },
                            mode: 'cors',
                            body: body,
                        };
                        if (WebPathConfiguration.host !== "http://localhost:8080") {
                            requestInfo.credentials = 'include';
                        } else {
                            console.log(`WebpPathConfiguration.host is ${WebPathConfiguration.host}`);
                        }
                        return fetch(uploadSnapshotURL, requestInfo)
                            .then(response => {
                                if (response.ok) {
                                    openSnapshotModal();
                                } else {
                                    openSnapshotFailureModal();
                                }
                                return response.json()
                            })
                            .then(data => {
                                console.log(data)
                            }).catch(console.error);
                    })
                })
            });
    }

    return (
        <>
            <Tooltip id="snapshot-button-tooltip" style={{zIndex: "1001"}}/>
            <Button style={{height: "2em"}} onClick={ClickSnapshotButton} data-tooltip-id="snapshot-button-tooltip" data-tooltip-content="スナップショット作成">
                <AiFillCamera/>
            </Button>
            <SnapshotSuccessModal>
                <View
                    ariaLabel="View example"
                    backgroundColor="var(--amplify-colors-white)"
                    borderRadius="6px"
                    border="1px solid var(--amplify-colors-black)"
                    boxShadow="3px 3px 5px 6px var(--amplify-colors-neutral-60)"
                    color="var(--amplify-colors-blue-60)"
                    maxWidth="100%"
                    padding="1rem"
                >
                    <div>スナップショットをアップロードしました</div>
                    <Button onClick={closeSnapshotModal}>閉じる</Button>
                </View>
            </SnapshotSuccessModal>
            <SnapshotFailureModal>
                <View
                    ariaLabel="View example"
                    backgroundColor="var(--amplify-colors-white)"
                    borderRadius="6px"
                    border="1px solid var(--amplify-colors-black)"
                    boxShadow="3px 3px 5px 6px var(--amplify-colors-neutral-60)"
                    color="var(--amplify-colors-blue-60)"
                    maxWidth="100%"
                    padding="1rem"
                >
                    <div>スナップショットのアップロードに失敗しました</div>
                    <Button onClick={closeSnapshotFailureModal}>閉じる</Button>
                </View>
            </SnapshotFailureModal>
        </>
    )

}