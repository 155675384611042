import {OpenSeadragonRectangleAnnotations} from "./OpenSeadragonRectangleAnnotations";
import {useEffect, useState} from "react";
import {AnnotationType} from "./OpenSeadragonAnnotationType";
import OpenSeadragon from "openseadragon";
import {useModal} from "react-hooks-use-modal";
import {AnnotationTextShadow, RectStyle, TextAnnotationGreen} from "./OpenSeadragonAnnotationConstants";
import {calculateRectangleInfo} from "./OpenSeadragonCalculateRectangleInfo";
import {APIServerConfiguration} from "../../../../config/APIServerConfiguration";
import {Auth} from "aws-amplify";
import {OpenSeadragonEvent} from "./OpenSeadragonEvent";
import {Button, TextAreaField, View} from "@aws-amplify/ui-react";
import {clearHandlers} from "./util/OpenSeadragonUtil";

const createAreaTextNode = (width ,height, unit) => {
    const textDiv = document.createElement('div');
    textDiv.style.color = `${TextAnnotationGreen}`;
    textDiv.style.position = "relative";
    textDiv.style.fontSize = "1.5em";
    textDiv.style.textShadow = AnnotationTextShadow;
    textDiv.style.top = "-2em";
    textDiv.style.whiteSpace = "nowrap";
    textDiv.style.wordBreak = "keep-all";
    textDiv.style.overflow = "visible";

    const text = document.createTextNode(`${(width * height).toPrecision(4)} ${unit}`);
    const sup = document.createElement("sup");
    const supValue = document.createTextNode("2");
    sup.appendChild(supValue);
    textDiv.appendChild(text);
    textDiv.appendChild(sup);
    return textDiv;
}

export const appendRectangleAnnotation = (viewer, annotation, annotations, setAnnotations) => {
    console.log("append rectangle annotation");
    setAnnotations({
        slideId: annotations.slideId,
        annotations: [...annotations.annotations, annotation]
    });
    // stataに反映してここでは削除。stateの更新に矩形の描画は任せる。
    viewer.removeOverlay(annotation.annotationId);
}

export const OpenSeadragonRectangleAnnotationsController = ({viewer, data, annotations, setAnnotations, rotate, controllerValue, isAnnotationVisible, reset}) => {
    const [annotation, setAnnotation] = useState({
        type: AnnotationType.Rectangle,
    });
    const [annotationId, setAnnotationId] = useState("");
    const [startPoint, setStartPoint] = useState(new OpenSeadragon.Point(0, 0));

    const [CreateAnnotationModal, openCreateAnnotationModal, closeCreateAnnotationModal, isOpenCreateAnnotationModal] = useModal();


    const prepareRect = (annotationId) => {

        const annotationRect = document.createElement('div');
        annotationRect.id = annotationId;
        annotationRect.style.border = RectStyle.border;
        annotationRect.style.background = RectStyle.background;
        annotationRect.onclick = (event) => {console.log(event);}

        return annotationRect;
    }
    const initializeRect = (annotationId, position) => {
        const annotationRect = prepareRect(annotationId);

        const point = viewer.viewport.pointFromPixel(position);
        console.log(point);
        const location = new OpenSeadragon.Rect(point.x, point.y, 0, 0);

        console.log(annotationId);
        console.log(location);
        viewer.addOverlay(annotationRect, location);
    }

    const clickEvent = (event) => {
        event.preventDefaultAction = true;
        console.log("clicked");
    }

    let startPosition = {
        x: 0,
        y:0
    };
    const pressEvent = (event) => {
        event.preventDefaultAction = true;
        console.log("pressed")
        const point = viewer.viewport.pointFromPixel(event.position);
        setStartPoint(viewer.viewport.pointFromPixel(event.position));
        const annotationId = crypto.randomUUID();
        setAnnotation({
            type: AnnotationType.Rectangle,
            annotationId: annotationId,
            startX: point.x,
            startY: point.y,
        })
        startPosition = {
            x: event.position.x,
            y: event.position.y
        }
        initializeRect(annotationId, event.position);
    }

    const drawRect = (event) => {
        setStartPoint(prevStartPoint => {
            if(prevStartPoint) {
                setAnnotation(prevAnnotation => {
                    if (prevAnnotation) {
                        console.log(`startPosition: ${startPosition.x}, ${startPosition.y}`);
                        const eventPoint = viewer.viewport.pointFromPixel(event.position);
                        const width = Math.abs(eventPoint.x - prevStartPoint.x);
                        const height = Math.abs(eventPoint.y - prevStartPoint.y);

                        const startPositionX = prevStartPoint.x < eventPoint.x ? prevStartPoint.x : eventPoint.x;
                        const startPositionY = prevStartPoint.y < eventPoint.y ? prevStartPoint.y : eventPoint.y;
                        const endPositionX = prevStartPoint.x < eventPoint.x ? eventPoint.x : prevStartPoint.x;
                        const endPositionY = prevStartPoint.y < eventPoint.y ? eventPoint.y : prevStartPoint.y;

                        const location = new OpenSeadragon.Rect(startPositionX, startPositionY, width, height);
                        const rect = prepareRect(prevAnnotation.annotationId);

                        // 面積計算
                        const startPointPixel = viewer.viewport.pixelFromPoint(prevStartPoint);
                        // const viewWidth = Math.abs(event.position.x - startPointPixel.x);
                        // const viewHeight = Math.abs(event.position.y - startPointPixel.y);
                        const viewWidth = Math.abs(event.position.x - startPosition.x);
                        const viewHeight = Math.abs(event.position.y - startPosition.y);
                        console.log(`viewWidth: ${viewWidth}, viewHeight: ${viewHeight}`);
                        const area = calculateRectangleInfo(viewer, data, viewWidth, viewHeight);
                        const textDiv = createAreaTextNode(area.width, area.height, area.unit);
                        rect.appendChild(textDiv);

                        viewer.removeOverlay(prevAnnotation.annotationId);
                        viewer.addOverlay(rect, location);

                        setAnnotation({
                            ...prevAnnotation,
                            startX: startPositionX,
                            startY: startPositionY,
                            endX: endPositionX,
                            endY: endPositionY,
                            width: area.width,
                            height: area.height,
                            unit: area.unit,
                        });
                    }
                });
                setStartPoint(prevStartPoint); //初期位置をセットし直す
            }
        });
    }

    const releaseEvent = (event) => {
        event.preventDefaultAction = true;
        console.log("released")
        const position = event.position;
        const eventPoint = viewer.viewport.pointFromPixel(position);
        console.log(eventPoint);


        drawRect(event);
        openCreateAnnotationModal();
    }

    const draggingEvent = (event) => {
        event.preventDefaultAction = true;
        console.log("dragging");
        drawRect(event);
    }

    const dragEnd = (event) => {
        event.preventDefaultAction = true;
    }

    const clickCreateAnnotationOKButton = (event) => {
        event.preventDefault();
        closeCreateAnnotationModal();
        const postAnnotationUrl = `${APIServerConfiguration.host}/v1/slides/${annotations.slideId}/annotation`;
        Auth.currentSession().then(data => {
            const accessToken = data.getAccessToken().getJwtToken();
            return fetch(postAnnotationUrl, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${accessToken}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(annotation)
                }
            ).then(response => {
                appendRectangleAnnotation(viewer, annotation, annotations, setAnnotations);
            }).catch((error) => {
                console.error(error);
            });
        })
    }

    const clickCreateAnnotationCancelButton = (event) => {
        viewer.removeOverlay(annotation.annotationId);
        closeCreateAnnotationModal();
        setAnnotation({
            slideId: annotations.slideId
        })
    };

    const preventDefault = (event) => {
        event.preventDefaultAction = true;
        console.log(event);
    }

    useEffect(() => {
        if(viewer) {
            if(controllerValue) {
                console.log(`controllerValueが変更されました。${controllerValue}`);
            }
            switch (controllerValue) {
                case AnnotationType.Rectangle:
                    clearHandlers(viewer);
                    viewer.addOnceHandler(OpenSeadragonEvent.CanvasClick, clickEvent)
                    viewer.addOnceHandler(OpenSeadragonEvent.CanvasPress, pressEvent);
                    viewer.addOnceHandler(OpenSeadragonEvent.CanvasRelease, releaseEvent)
                    viewer.addHandler(OpenSeadragonEvent.CanvasDrag, draggingEvent)
                    viewer.addOnceHandler(OpenSeadragonEvent.CanvasDragEnd, dragEnd);
                    viewer.addOnceHandler(OpenSeadragonEvent.CanvasRelease, (event) => {
                        if (reset) {
                            reset("");
                        }
                    })
                    break;
                case AnnotationType.Delete:
                    clearHandlers(viewer);
                    console.log("変更されたよ")
                    viewer.setMouseNavEnabled(false);
                    viewer.addOnceHandler(OpenSeadragonEvent.CanvasRelease, (event) => {
                        event.preventDefaultAction = true;
                    })
                    break;
                case AnnotationType.Select:
                    clearHandlers(viewer);
                    viewer.setMouseNavEnabled(false);
                    viewer.addOnceHandler(OpenSeadragonEvent.CanvasRelease, (event) => {
                        event.preventDefaultAction = true;
                    })
                    break;
                case AnnotationType.Move:
                    clearHandlers(viewer);
                    viewer.setMouseNavEnabled(false);
                    viewer.addOnceHandler(OpenSeadragonEvent.CanvasRelease, (event) => {
                        event.preventDefaultAction = true;
                    })

                // default:
                //     console.log("ハンドラーを削除するよ。")
                //     clearHandlers();
            }
        }
    },[controllerValue]);
    return (
        <>
            <OpenSeadragonRectangleAnnotations
                viewer={viewer}
                data={data}
                annotations={annotations}
                setAnnotations={setAnnotations}
                rotate={rotate}
                controllerValue={controllerValue}
                isAnnotationVisible={isAnnotationVisible}
                reset={reset}
            />
            <CreateAnnotationModal>
                <View
                    ariaLabel="View example"
                    backgroundColor="var(--amplify-colors-white)"
                    borderRadius="6px"
                    border="1px solid var(--amplify-colors-black)"
                    boxShadow="3px 3px 5px 6px var(--amplify-colors-neutral-60)"
                    color="var(--amplify-colors-blue-60)"
                    maxWidth="100%"
                    padding="1rem"
                >
                    <form>
                        <TextAreaField
                            label="説明"
                            rows="3"
                            onChange={(e) => {
                                console.log(e.target.value)
                                setAnnotation({...annotation,
                                    annotationText: e.target.value
                                })
                            }}
                        />
                    </form>
                    <Button onClick={clickCreateAnnotationOKButton}>OK</Button>
                    <Button onClick={clickCreateAnnotationCancelButton}>キャンセル</Button>
                </View>
            </CreateAnnotationModal>
        </>
    )
}